const actions = {
  FETCH_ACCOUNTS_BEGIN: 'FETCH_ACCOUNTS_BEGIN',
  FETCH_ACCOUNTS_SUCCESS: 'FETCH_ACCOUNTS_SUCCESS',
  FETCH_ACCOUNTS_ERR: 'FETCH_ACCOUNTS_ERR',

  ADD_TO_ACCOUNT_BEGIN: 'ADD_TO_ACCOUNT_BEGIN',
  ADD_TO_ACCOUNT_SUCCESS: 'ADD_TO_ACCOUNT_SUCCESS',
  ADD_TO_ACCOUNT_ERR: 'ADD_TO_ACCOUNT_ERR',

  FETCH_ACTIVITY_BEGIN: 'FETCH_ACTIVITY_BEGIN',
  FETCH_ACTIVITY_SUCCESS: 'FETCH_ACTIVITY_SUCCESS',
  FETCH_ACTIVITY_ERR: 'FETCH_ACTIVITY_ERR',

  FETCH_ACCOUNTS_LIST_BEGIN: 'FETCH_ACCOUNTS_LIST_BEGIN',
  FETCH_ACCOUNTS_LIST_SUCCESS: 'FETCH_ACCOUNTS_LIST_SUCCESS',
  FETCH_ACCOUNTS_LIST_ERR: 'FETCH_ACCOUNTS_LIST_ERR',

  fetchAccountsBegin: () => ({
    type: actions.FETCH_ACCOUNTS_BEGIN,
  }),

  fetchAccountsSuccess: (data) => ({
    type: actions.FETCH_ACCOUNTS_SUCCESS,
    data,
  }),

  fetchAccountsErr: (err) => ({
    type: actions.FETCH_ACCOUNTS_ERR,
    err,
  }),

  addToAccountBegin: () => ({
    type: actions.ADD_TO_ACCOUNT_BEGIN,
  }),

  addToAccountSuccess: (data) => ({
    type: actions.ADD_TO_ACCOUNT_SUCCESS,
    data,
  }),

  addToAccountErr: (err) => ({
    type: actions.ADD_TO_ACCOUNT_ERR,
    err,
  }),

  fetchActivityBegin: () => ({
    type: actions.FETCH_ACTIVITY_BEGIN,
  }),

  fetchActivitySuccess: (data) => ({
    type: actions.FETCH_ACTIVITY_SUCCESS,
    data,
  }),

  fetchActivityErr: (err) => ({
    type: actions.FETCH_ACTIVITY_ERR,
    err,
  }),

  fetchAccountsListBegin: () => ({
    type: actions.FETCH_ACCOUNTS_LIST_BEGIN,
  }),

  fetchAccountsListSuccess: (data) => ({
    type: actions.FETCH_ACCOUNTS_LIST_SUCCESS,
    data,
  }),

  fetchAccountsListErr: (err) => ({
    type: actions.FETCH_ACCOUNTS_LIST_ERR,
    err,
  }),
};

export default actions;

import React from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import SideMenu from './SideMenu';
import AuthInfo from '../container/profile/usermenu/AuthInfo';
import useDeviceWidth from '../hooks/responsive';

const RightMenu = ({ toggleCollapsed, collapsed }) => {
  const deviceWidth = useDeviceWidth();

  return (
    <Space
      style={{
        textAlign: 'left',
      }}
    >
      <AuthInfo />
      {deviceWidth < 1080 && <SideMenu toggleCollapsed={toggleCollapsed} collapsed={collapsed} />}
    </Space>
  );
};

RightMenu.propTypes = {
  toggleCollapsed: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
};

export default RightMenu;

/* eslint-disable camelcase */
import { usermavenClient } from '@usermaven/sdk-js';
import { useEffect, useMemo } from 'react';
import { getItem } from './utility/localStorageControl';
import useUserPlan from './hooks/userPlan';

export function useUsermaven(opts) {
  const usermaven = useMemo(() => usermavenClient(opts), [opts]);
  return { usermaven };
}

export function usePageView(usermaven) {
  useEffect(() => {
    usermaven.track('pageview');
  }, [usermaven]);
}

export function useUserData(usermavens, isLoggedIn) {
  const { id, email, first_name, surname, created_at, company, parent_id } = getItem('user') || {};

  useEffect(() => {
    if (!isLoggedIn) return;

    const userPlan = useUserPlan();

    usermavens.id({
      id,
      email,
      first_name,
      last_name: surname,
      custom: {
        plan_name: userPlan,
        user_id: id,
        team_id: parent_id,
      },
      created_at,
      company: {
        id: company?.id,
        name: company?.name,
        created_at: company?.created_at,
      },
    });
  }, [isLoggedIn, usermavens, id, email, first_name, surname, created_at]);
}

import Styled from 'styled-components';
import { Button } from 'antd';

const ButtonGroup = Button.Group;

const square = (theme, type) => `
      background: ${type !== 'default' && theme[`${type}-color`]};
      border: 1px solid ${type !== 'default' ? theme[`${type}-color`] : theme['disabled-color']};
      color: ${type !== 'default' && '#ffffff'};
      border-radius: 0px;
      padding: 0px 15px;

      &:hover, &:focus {
          background: ${type !== 'default' && theme[`${type}-hover`]};
          border: 1px solid ${type !== 'default' && theme[`${type}-hover`]};
          color: ${type !== 'default' && '#ffffff'};
      }
  `;

const ButtonStyled = Styled(Button)`
    background: ${({ type, theme }) => (type === 'default' ? 'transparent' : theme[`${type}-color`])};
    border-width: 0px;
    border-style: ${({ type }) => (type !== 'dashed' ? 'solid' : 'dashed')};
    color: ${({ type }) => (type !== 'default' ? '#f4f4f4' : '#5A5F7D')};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: ${({ shape }) => (!shape ? '4px' : '40px')};
    /* padding: 0px 20.5px; */
    height: ${({ size, theme }) => (size !== 'default' ? theme[`btn-height-${size}`] : '42px')};
    font-weight: 500;
    box-shadow: 0 0;
    &:hover, &:focus {
        background: ${({ type, theme }) => type !== 'default' && theme[`${type}-hover`]};
        color: ${({ type }) => (type !== 'default' ? '#ffffff' : '#5A5F7D')};
    }
    i,
    svg,
    img{
        width: 16px;
        height: 16px;
        +span{
            ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 6px;
        }
    }

    ${({ squared, theme, type }) => squared && square(theme, type)};
`;

const ButtonStyledGroup = Styled(ButtonGroup)`
    >.ant-btn:first-child{
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
    }
    button {
        margin: 0px;
        padding: 0 10.75px;
        height: 30px;
        font-size: 12px;
        font-weight: 500;
    }
    .ant-btn-light:hover{
        background: #F4F5F7;
    }
`;

export { ButtonStyled, ButtonStyledGroup };

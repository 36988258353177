const actions = {
  // fetch todo
  TODO_FETCH_BEGIN: 'TODO_FETCH_BEGIN',
  TODO_FETCH_SUCCESS: 'TODO_FETCH_SUCCESS',
  TODO_FETCH_ERR: 'TODO_FETCH_ERR',

  // create todo
  TODO_CREATE_BEGIN: 'TODO_CREATE_BEGIN',
  TODO_CREATE_SUCCESS: 'TODO_CREATE_SUCCESS',
  TODO_CREATE_ERR: 'TODO_CREATE_ERR',

  // delete todo
  TODO_DELETE_BEGIN: 'TODO_DELETE_BEGIN',
  TODO_DELETE_SUCCESS: 'TODO_DELETE_SUCCESS',
  TODO_DELETE_ERR: 'TODO_DELETE_ERR',

  // update todo
  TODO_UPDATE_BEGIN: 'TODO_UPDATE_BEGIN',
  TODO_UPDATE_SUCCESS: 'TODO_UPDATE_SUCCESS',
  TODO_UPDATE_ERR: 'TODO_UPDATE_ERR',

  // bulk delete todo
  TODO_BULK_DELETE_BEGIN: 'TODO_BULK_DELETE_BEGIN',
  TODO_BULK_DELETE_SUCCESS: 'TODO_BULK_DELETE_SUCCESS',
  TODO_BULK_DELETE_ERR: 'TODO_BULK_DELETE_ERR',

  // bulk complete todo
  TODO_BULK_COMPLETE_BEGIN: 'TODO_BULK_COMPLETE_BEGIN',
  TODO_BULK_COMPLETE_SUCCESS: 'TODO_BULK_COMPLETE_SUCCESS',
  TODO_BULK_COMPLETE_ERR: 'TODO_BULK_COMPLETE_ERR',

  // bulk incomplete todo
  TODO_BULK_INCOMPLETE_BEGIN: 'TODO_BULK_INCOMPLETE_BEGIN',
  TODO_BULK_INCOMPLETE_SUCCESS: 'TODO_BULK_INCOMPLETE_SUCCESS',
  TODO_BULK_INCOMPLETE_ERR: 'TODO_BULK_INCOMPLETE_ERR',

  // pin
  TODO_PIN_BEGIN: 'TODO_PIN_BEGIN',
  TODO_PIN_SUCCESS: 'TODO_PIN_SUCCESS',
  TODO_PIN_ERR: 'TODO_PIN_ERR',

  // complete todo
  TODO_COMPLETE_BEGIN: 'TODO_COMPLETE_BEGIN',
  TODO_COMPLETE_SUCCESS: 'TODO_COMPLETE_SUCCESS',
  TODO_COMPLETE_ERR: 'TODO_COMPLETE_ERR',

  // view todo
  TODO_VIEW_BEGIN: 'TODO_VIEW_BEGIN',
  TODO_VIEW_SUCCESS: 'TODO_VIEW_SUCCESS',
  TODO_VIEW_ERR: 'TODO_VIEW_ERR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  // fetch todo
  todoFetchBegin: () => ({
    type: actions.TODO_FETCH_BEGIN,
  }),

  todoFetchSuccess: (data) => ({
    type: actions.TODO_FETCH_SUCCESS,
    data,
  }),

  todoFetchErr: (err) => ({
    type: actions.TODO_FETCH_ERR,
    err,
  }),

  // create todo
  todoCreateBegin: () => ({
    type: actions.TODO_CREATE_BEGIN,
  }),

  todoCreateSuccess: (data) => ({
    type: actions.TODO_CREATE_SUCCESS,
    data,
  }),

  todoCreateErr: (err) => ({
    type: actions.TODO_CREATE_ERR,
    err,
  }),

  // delete todo
  todoDeleteBegin: () => ({
    type: actions.TODO_DELETE_BEGIN,
  }),

  todoDeleteSuccess: (data) => ({
    type: actions.TODO_DELETE_SUCCESS,
    data,
  }),

  todoDeleteErr: (err) => ({
    type: actions.TODO_DELETE_ERR,
    err,
  }),

  // update todo
  todoUpdateBegin: () => ({
    type: actions.TODO_UPDATE_BEGIN,
  }),

  todoUpdateSuccess: (data) => ({
    type: actions.TODO_UPDATE_SUCCESS,
    data,
  }),

  todoUpdateErr: (err) => ({
    type: actions.TODO_UPDATE_ERR,
    err,
  }),

  // bulk delete todo
  todoBulkDeleteBegin: () => ({
    type: actions.TODO_BULK_DELETE_BEGIN,
  }),

  todoBulkDeleteSuccess: (data) => ({
    type: actions.TODO_BULK_DELETE_SUCCESS,
    data,
  }),

  todoBulkDeleteErr: (err) => ({
    type: actions.TODO_BULK_DELETE_ERR,
    err,
  }),

  // bulk complete todo
  todoBulkCompleteBegin: () => ({
    type: actions.TODO_BULK_COMPLETE_BEGIN,
  }),

  todoBulkCompleteSuccess: (data) => ({
    type: actions.TODO_BULK_COMPLETE_SUCCESS,
    data,
  }),

  todoBulkCompleteErr: (err) => ({
    type: actions.TODO_BULK_COMPLETE_ERR,
    err,
  }),
  // bulk incomplete todo
  todoBulkIncompleteBegin: () => ({
    type: actions.TODO_BULK_INCOMPLETE_BEGIN,
  }),

  todoBulkIncompleteSuccess: (data) => ({
    type: actions.TODO_BULK_INCOMPLETE_SUCCESS,
    data,
  }),

  todoBulkIncompleteErr: (err) => ({
    type: actions.TODO_BULK_INCOMPLETE_ERR,
    err,
  }),

  // view todo
  todoViewBegin: () => ({
    type: actions.TODO_VIEW_BEGIN,
  }),

  todoViewSuccess: (data) => ({
    type: actions.TODO_VIEW_SUCCESS,
    data,
  }),

  todoViewErr: (err) => ({
    type: actions.TODO_VIEW_ERR,
    err,
  }),

  // pin
  todoPinBegin: () => ({
    type: actions.TODO_PIN_BEGIN,
  }),

  todoPinSuccess: (data) => ({
    type: actions.TODO_PIN_SUCCESS,
    data,
  }),

  todoPinErr: (err) => ({
    type: actions.TODO_PIN_ERR,
    err,
  }),

  // complete todo
  todoCompleteBegin: () => ({
    type: actions.TODO_COMPLETE_BEGIN,
  }),

  todoCompleteSuccess: (data) => ({
    type: actions.TODO_COMPLETE_SUCCESS,
    data,
  }),

  todoCompleteErr: (err) => ({
    type: actions.TODO_COMPLETE_ERR,
    err,
  }),

  // clear error
  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
};

export default actions;

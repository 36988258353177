import Styled from 'styled-components';

// ${require('../../../../static/img/auth/BG.png')}
const Aside = Styled.aside`
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: url("");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left top;
  @media only screen and (max-width: 767px){
    height: 100%;
  }
  .topShape {
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
  }
  .bottomShape {
    position: absolute;
    bottom: 0;
    left: 0;
    //width: 400px;
  }
  .auth-side-content{
    @media only screen and (max-width: 991px){
      h1{
        font-size: 20px;
      }
    }
    @media only screen and (max-width: 767px){
      h1{
        font-size: 24px;
        margin-bottom: 28px;
      }
    }
  }
`;

const Content = Styled.div`
    padding: 100px;
    @media only screen and (max-width: 1599px){
      padding: 50px;
    }
    @media only screen and (max-width: 991px){
      padding: 20px;
    }
    @media only screen and (max-width: 767px){
      text-align: center;
    }
    .auth-content-figure{
      @media only screen and (max-width: 1199px){
        max-width: 420px;
      }
      @media only screen and (max-width: 991px){
        max-width: 100%;
      }
    }
`;

const AuthWrapper = Styled.div`
  height: 100%;
  padding: 40px;
  background-color:#FAFAFA;


  .react-tel-input input[type='tel'],
  .form-control{
    background-color: transparent;
  }

  @media only screen and (max-width: 1599px){
    padding: 25px;
  }

  /* @media only screen and (max-width: 767px){
    text-align: center;
  } */
  .auth-notice{
    text-align: right;
    font-weight: 500;
    color: ${({ theme }) => theme['gray-color']};
    @media only screen and (max-width: 767px){
      text-align: center;
      margin-bottom: 10px;
    }
  }
  button{
    &.btn-signin{
      min-width: 185px;
    }
    &.btn-create{
      border-radius: 8px;
      min-width: 205px;
    }
    &.btn-reset{
      border-radius: 8px;
      min-width: 260px;
    }
    &.ant-btn-lg{
      font-size: 14px;
      font-weight: 500;
      height: 48px;
    }
  }
  .auth-contents{
  }
`;

const FooterWrapper = Styled.div`
  border-top: 1px solid #EBEBEB;
  padding-top: 80px;
    @media only screen and (max-width: 767px){
       padding-top: 40px; 
    }
  width: 100%;
      overflow: hidden;
  .foo{
    position: relative;
    max-width: 1600px;
    margin: 0 auto;
  }
  .foo-d-wrap{
    display: flex;
    .foo-icon{
      margin-right: 25px;
    }
  }
`;

const LayoutWrapper = Styled.div`
  position: relative;
  background-color: #f4f4f4;
  height: 100vh;
`;

const TopNavWrapper = Styled.div`
  position: relative;
  background-color: #FAFAFA;
  border-bottom: 1px solid #EBEBEB;
  padding: 10px 40px 5px 40px;
`;

export { Aside, Content, AuthWrapper, FooterWrapper, LayoutWrapper, TopNavWrapper };

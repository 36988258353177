import actions from './actions';

const {
  CATEGORY_CREATING_FROM_SELECT_SUCCESS,
  CATEGORY_CREATING_FROM_SELECT_ERR,
  CATEGORY_CREATING_FROM_SELECT_BEGIN,
  CATEGORY_CREATING_FROM_SELECT_CLOSE_MODEL,
  CLEAR_SELECT_STATE,

  CATEGORY_FETCH_BEGIN,
  CATEGORY_FETCH_SUCCESS,
  CATEGORY_FETCH_ERR,

  CATEGORY_CREATE_BEGIN,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_CREATE_ERR,

  CATEGORY_DELETE_BEGIN,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DELETE_ERR,

  CATEGORY_BULK_DELETE_BEGIN,
  CATEGORY_BULK_DELETE_SUCCESS,
  CATEGORY_BULK_DELETE_ERR,

  CATEGORY_SEARCH_BEGIN,
  CATEGORY_SEARCH_SUCCESS,
  CATEGORY_SEARCH_ERR,

  CATEGORY_UPDATE_BEGIN,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_ERR,
  CLEAR_ERROR,
} = actions;

const initState = {
  categories: [],
  searchResult: [],
  error: null,
  count: 0,
  loading: false,
  isAddingCategory: false,
  createdFromSelectCategory: '',
  selectCreatingCategoryName: '',
  bulkMessage: '',
};

// eslint-disable-next-line default-param-last
const CategoryReducer = (state = initState, action) => {
  const { type, data, ids, err } = action;
  switch (type) {
    case CATEGORY_CREATING_FROM_SELECT_BEGIN:
      return {
        ...state,
        isAddingCategory: true,
        selectCreatingCategoryName: data,
      };
    case CATEGORY_CREATING_FROM_SELECT_SUCCESS:
      return {
        ...state,
        createdFromSelectCategory: data,
        isAddingCategory: false,
      };
    case CATEGORY_CREATING_FROM_SELECT_ERR:
      return {
        ...state,
        error: err,
        isAddingCategory: false,
      };
    case CATEGORY_CREATING_FROM_SELECT_CLOSE_MODEL:
      return {
        ...state,
        selectCreatingCategoryName: '',
        isAddingCategory: false,
      };
    case CLEAR_SELECT_STATE:
      return {
        ...state,
        createdFromSelectCategory: '',
        selectCreatingCategoryName: '',
      };
    case CATEGORY_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CATEGORY_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: data.data,
        count: data.total,
      };

    case CATEGORY_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CATEGORY_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: [...state.categories, data],
      };
    case CATEGORY_CREATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CATEGORY_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: state.categories.filter((category) => category.id !== data),
      };
    case CATEGORY_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CATEGORY_BULK_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CATEGORY_BULK_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: state.categories.filter((category) => !ids.includes(category.id)),
        bulkMessage: data,
      };
    case CATEGORY_BULK_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };

    case CATEGORY_SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CATEGORY_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchResult: data,
      };

    case CATEGORY_SEARCH_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CATEGORY_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: state.categories.map((category) => {
          if (category.id === data.id) {
            return data;
          }
          return category;
        }),
      };
    case CATEGORY_UPDATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        bulkMessage: '',
      };
    default:
      return state;
  }
};

export default CategoryReducer;

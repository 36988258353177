const actions = {
  TEAM_SEARCH_BEGIN: 'TEAM_SEARCH_BEGIN',
  TEAM_SEARCH_SUCCESS: 'TEAM_SEARCH_SUCCESS',
  TEAM_SEARCH_ERR: 'TEAM_SEARCH_ERR',

  TEAM_FETCH_BEGIN: 'TEAM_FETCH_BEGIN',
  TEAM_FETCH_SUCCESS: 'TEAM_FETCH_SUCCESS',
  TEAM_FETCH_ERR: 'TEAM_FETCH_ERR',

  TEAM_DELETE_BEGIN: 'TEAM_DELETE_BEGIN',
  TEAM_DELETE_SUCCESS: 'TEAM_DELETE_SUCCESS',
  TEAM_DELETE_ERR: 'TEAM_DELETE_ERR',

  TEAM_VIEW_BEGIN: 'TEAM_VIEW_BEGIN',
  TEAM_VIEW_SUCCESS: 'TEAM_VIEW_SUCCESS',
  TEAM_VIEW_ERR: 'TEAM_VIEW_ERR',

  // view task
  TEAM_TASK_VIEW_BEGIN: 'TEAM_TASK_VIEW_BEGIN',
  TEAM_TASK_VIEW_SUCCESS: 'TEAM_TASK_VIEW_SUCCESS',
  TEAM_TASK_VIEW_ERR: 'TEAM_TASK_VIEW_ERR',

  TEAM_MESSAGE_VIEW_BEGIN: 'TEAM_MESSAGE_VIEW_BEGIN',
  TEAM_MESSAGE_VIEW_SUCCESS: 'TEAM_MESSAGE_VIEW_SUCCESS',
  TEAM_MESSAGE_VIEW_ERR: 'TEAM_MESSAGE_VIEW_ERR',

  TEAM_POINT_FETCH_BEGIN: 'TEAM_POINT_FETCH_BEGIN',
  TEAM_POINT_FETCH_SUCCESS: 'TEAM_POINT_FETCH_SUCCESS',
  TEAM_POINT_FETCH_ERR: 'TEAM_POINT_FETCH_ERR',

  TEAM_POINT_RESET_BEGIN: 'TEAM_POINT_RESET_BEGIN',
  TEAM_POINT_RESET_SUCCESS: 'TEAM_POINT_RESET_SUCCESS',
  TEAM_POINT_RESET_ERR: 'TEAM_POINT_RESET_ERR',

  TEAM_MEMBER_POINT_RESET_BEGIN: 'TEAM_MEMBER_POINT_RESET_BEGIN',
  TEAM_MEMBER_POINT_RESET_SUCCESS: 'TEAM_MEMBER_POINT_RESET_SUCCESS',
  TEAM_MEMBER_POINT_RESET_ERR: 'TEAM_MEMBER_POINT_RESET_ERR',

  teamSearchBegin: () => ({
    type: actions.TEAM_SEARCH_BEGIN,
  }),

  teamSearchSuccess: (data) => ({
    type: actions.TEAM_SEARCH_SUCCESS,
    data,
  }),

  teamSearchErr: (err) => ({
    type: actions.TEAM_SEARCH_ERR,
    err,
  }),

  teamFetchBegin: () => ({
    type: actions.TEAM_FETCH_BEGIN,
  }),

  teamFetchSuccess: (data) => ({
    type: actions.TEAM_FETCH_SUCCESS,
    data,
  }),

  teamFetchErr: (err) => ({
    type: actions.TEAM_FETCH_ERR,
    err,
  }),

  teamDeleteBegin: () => ({
    type: actions.TEAM_DELETE_BEGIN,
  }),

  teamDeleteSuccess: (data) => ({
    type: actions.TEAM_DELETE_SUCCESS,
    data,
  }),

  teamDeleteErr: (err) => ({
    type: actions.TEAM_DELETE_ERR,
    err,
  }),

  teamViewBegin: () => ({
    type: actions.TEAM_VIEW_BEGIN,
  }),

  teamViewSuccess: (data) => ({
    type: actions.TEAM_VIEW_SUCCESS,
    data,
  }),

  teamViewErr: (err) => ({
    type: actions.TEAM_VIEW_ERR,
    err,
  }),

  teamTaskViewBegin: () => ({
    type: actions.TEAM_TASK_VIEW_BEGIN,
  }),

  teamTaskViewSuccess: (data) => ({
    type: actions.TEAM_TASK_VIEW_SUCCESS,
    data,
  }),

  teamTaskViewErr: (err) => ({
    type: actions.TEAM_TASK_VIEW_ERR,
    err,
  }),

  teamMessageViewBegin: () => ({
    type: actions.TEAM_MESSAGE_VIEW_BEGIN,
  }),

  teamMessageViewSuccess: (data) => ({
    type: actions.TEAM_MESSAGE_VIEW_SUCCESS,
    data,
  }),

  teamMessageViewErr: (err) => ({
    type: actions.TEAM_MESSAGE_VIEW_ERR,
    err,
  }),

  teamPointFetchBegin: () => ({
    type: actions.TEAM_POINT_FETCH_BEGIN,
  }),

  teamPointFetchSuccess: (data) => ({
    type: actions.TEAM_POINT_FETCH_SUCCESS,
    data,
  }),

  teamPointFetchErr: (err) => ({
    type: actions.TEAM_POINT_FETCH_ERR,
    err,
  }),

  teamPointResetBegin: () => ({
    type: actions.TEAM_POINT_RESET_BEGIN,
  }),

  teamPointResetSuccess: (data) => ({
    type: actions.TEAM_POINT_RESET_SUCCESS,
    data,
  }),

  teamPointResetErr: (err) => ({
    type: actions.TEAM_POINT_RESET_ERR,
    err,
  }),

  teamMemberPointResetBegin: () => ({
    type: actions.TEAM_MEMBER_POINT_RESET_BEGIN,
  }),

  teamMemberPointResetSuccess: (data) => ({
    type: actions.TEAM_MEMBER_POINT_RESET_SUCCESS,
    data,
  }),

  teamMemberPointResetErr: (err) => ({
    type: actions.TEAM_MEMBER_POINT_RESET_ERR,
    err,
  }),
};

export default actions;

import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import WithAdminLayout from '../../layout/withAdminLayout';

const Dashboard = lazy(() => import('../../container/dashboard'));
const Contacts = lazy(() => import('../../container/contacts'));
const CreateContacts = lazy(() => import('../../container/contacts/CreateContacts'));
const ViewContacts = lazy(() => import('../../container/contacts/ViewContacts'));
const EditContacts = lazy(() => import('../../container/contacts/EditContacts'));
const ImportContact = lazy(() => import('../../container/contacts/ImportContact'));
const StageContact = lazy(() => import('../../container/contacts/StageContact'));
const ActivityContact = lazy(() => import('../../container/contacts/ActivityContact'));

const Messages = lazy(() => import('../../container/messages'));
const Todo = lazy(() => import('../../container/todo'));
const TodoTemplate = lazy(() => import('../../container/todo/template/TodoTemplate'));
const TodoTemplateCreate = lazy(() => import('../../container/todo/template/TodoTemplateCreate'));
const TodoTemplateEdit = lazy(() => import('../../container/todo/template/TodoTemplateEdit'));

const Funnel = lazy(() => import('../../container/funnel'));
const ArchivedFunnel = lazy(() => import('../../container/funnel/ArchivedFunnel'));
const FunnelLinks = lazy(() => import('../../container/funnel/FunnelLinks'));
const Invitation = lazy(() => import('../../container/funnel/business-owner/invitation'));
const FunnelJoined = lazy(() => import('../../container/funnel/business-owner/FunnelJoined'));
const FunnelPage = lazy(() => import('../../container/funnel/FunnelPage'));
const AllTemplates = lazy(() => import('../../container/funnel/AllTemplates'));
const CreateTemplate = lazy(() => import('../../container/funnel/CreateTemplate'));
const EditTemplate = lazy(() => import('../../container/funnel/EditTemplate'));
const EditTemplateBuilder = lazy(() => import('../../container/funnel/EditTemplateBuilder'));
const StepBuilder = lazy(() => import('../../container/funnel/StepBuilder'));

const CustomerTracker = lazy(() => import('../../container/customer-tracker'));
const Products = lazy(() => import('../../container/products'));
const CreateProduct = lazy(() => import('../../container/products/CreateProduct'));
const EditProduct = lazy(() => import('../../container/products/EditProduct'));

const Resources = lazy(() => import('../../container/resources'));
const EditResources = lazy(() => import('../../container/resources/EditResources'));
const CreateResources = lazy(() => import('../../container/resources/CreateResources'));

const Reports = lazy(() => import('../../container/reports'));
const OrderReport = lazy(() => import('../../container/reports/OrderReport'));
const OnlineOrderReport = lazy(() => import('../../container/reports/OnlineOrderReport'));
const MessageReport = lazy(() => import('../../container/reports/MessageReport'));
const TaskReport = lazy(() => import('../../container/reports/TaskReport'));

const Teams = lazy(() => import('../../container/teams'));
const OrganisationList = lazy(() => import('../../container/teams/OrganisationList'));
// const Inbox = lazy(() => import('../../container/teams/inbox'));
const Notes = lazy(() => import('../../container/notes'));
const Categories = lazy(() => import('../../container/categories'));
const EditCategory = lazy(() => import('../../container/categories/EditCategory'));
const Settings = lazy(() => import('../../container/settings'));
const Domain = lazy(() => import('../../container/domains'));
const AddDomain = lazy(() => import('../../container/domains/AddDomain'));
const PointDomain = lazy(() => import('../../container/domains/PointDomain'));
const Account = lazy(() => import('../../container/account'));
const Upgrade = lazy(() => import('../../container/account/Upgrade'));
const CheckOut = lazy(() => import('../../container/account/CheckOut'));
const Integration = lazy(() => import('../../container/settings/Integration'));
const ApiIntegration = lazy(() => import('../../container/settings/ApiIntegration'));
const MessageTemplate = lazy(() => import('../../container/messages/MessageTemplate'));
const CompletedTask = lazy(() => import('../../container/todo/CompletedTask'));
const ScheduledTask = lazy(() => import('../../container/todo/ScheduledTask'));
const OnlineOrder = lazy(() => import('../../container/customer-tracker/OnlineOrder'));
// const PendingInvitation = lazy(() => import('../../container/teams/PendingInvitation'));
// const Invite = lazy(() => import('../../container/teams/Invite'));
// const SendItem = lazy(() => import('../../container/teams/inbox/SendItem'));
const TeamMember = lazy(() => import('../../container/teams/TeamMember'));
const TeamSwitch = lazy(() => import('../../container/teams/switch/TeamSwitch'));
const TodoCreate = lazy(() => import('../../container/todo/TodoCreate'));
const EditCreate = lazy(() => import('../../container/todo/EditCreate'));
// const TeamsAddMessage = lazy(() => import('../../container/teams/TeamsAddMessage'));

const Tags = lazy(() => import('../../container/tags'));
const CreateTag = lazy(() => import('../../container/tags/CreateTag'));
const EditTag = lazy(() => import('../../container/tags/EditTag'));

const CreateCategory = lazy(() => import('../../container/categories/CreateCategory'));
const CreateOrder = lazy(() => import('../../container/customer-tracker/CreateOrder'));
const EditOrder = lazy(() => import('../../container/customer-tracker/EditOrder'));
const CreateMessage = lazy(() => import('../../container/messages/CreateMessage'));
const CreateMessageTemplate = lazy(() => import('../../container/messages/CreateMessageTemplate'));
const EditMessageTemplate = lazy(() => import('../../container/messages/EditMessageTemplate'));
const ImportProducts = lazy(() => import('../../container/products/ImportProducts'));
const NotificationPage = lazy(() => import('../../container/notifications/NotificationPage'));
const PremiumFeature = lazy(() => import('../../container/profile/authentication/PremiumFeature'));
const NotFound = lazy(() => import('../../container/404/NotFound'));
const EditProfile = lazy(() => import('../../container/account/EditProfile'));
const ChangePassword = lazy(() => import('../../container/account/ChangePassword'));
const Search = lazy(() => import('../../container/search/search'));

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path={path} component={Dashboard} />
        <Route exact path={`${path}/contacts`} component={Contacts} />
        <Route exact path={`${path}/contacts/create`} component={CreateContacts} />
        <Route exact path={`${path}/contacts/view/:id`} component={ViewContacts} />
        <Route exact path={`${path}/contacts/edit/:id`} component={EditContacts} />
        <Route exact path={`${path}/contacts/import`} component={ImportContact} />
        <Route exact path={`${path}/contacts/stages`} component={StageContact} />
        <Route exact path={`${path}/contacts/activity/:contactId`} component={ActivityContact} />

        <Route exact path={`${path}/messages`} component={Messages} />
        <Route exact path={`${path}/messages/create`} component={CreateMessage} />
        <Route exact path={`${path}/messages-template`} component={MessageTemplate} />
        <Route exact path={`${path}/messages-template/create`} component={CreateMessageTemplate} />
        <Route exact path={`${path}/messages-template/edit/:id`} component={EditMessageTemplate} />

        <Route exact path={`${path}/todo-list`} component={Todo} />
        <Route exact path={`${path}/todo-list/create`} component={TodoCreate} />
        <Route exact path={`${path}/todo-list/edit/:id`} component={EditCreate} />
        <Route exact path={`${path}/completed-task`} component={CompletedTask} />
        <Route exact path={`${path}/scheduled`} component={ScheduledTask} />

        {/* todo template */}
        <Route exact path={`${path}/todo-template`} component={TodoTemplate} />
        <Route exact path={`${path}/todo-template/create`} component={TodoTemplateCreate} />
        <Route exact path={`${path}/todo-template/edit/:id`} component={TodoTemplateEdit} />

        {/* Funnel */}
        <Route exact path={`${path}/sales-funnel`} component={Funnel} />
        <Route exact path={`${path}/sales-funnel/funnel/:id`} component={FunnelPage} />
        <Route exact path={`${path}/sales-funnel/funnel/edit/:id`} component={StepBuilder} />
        <Route exact path={`${path}/sales-funnel/all-templates`} component={AllTemplates} />
        <Route exact path={`${path}/sales-funnel/create-template`} component={CreateTemplate} />
        <Route exact path={`${path}/sales-funnel/edit-template/:funnelId`} component={EditTemplate} />
        <Route exact path={`${path}/sales-funnel/edit/:funnelId`} component={EditTemplateBuilder} />

        <Route exact path={`${path}/funnel-links`} component={FunnelLinks} />
        <Route exact path={`${path}/funnel-invitation`} component={Invitation} />
        <Route exact path={`${path}/funnel-joined`} component={FunnelJoined} />
        <Route exact path={`${path}/customer-tracker`} component={CustomerTracker} />
        <Route exact path={`${path}/order/create/:contactId`} component={CreateOrder} />
        <Route exact path={`${path}/order/edit/:orderId`} component={EditOrder} />
        <Route exact path={`${path}/online-order`} component={OnlineOrder} />

        <Route exact path={`${path}/funnel-archived`} component={ArchivedFunnel} />

        <Route exact path={`${path}/products`} component={Products} />
        <Route exact path={`${path}/products/import`} component={ImportProducts} />
        <Route exact path={`${path}/products/create`} component={CreateProduct} />
        <Route exact path={`${path}/products/edit/:id`} component={EditProduct} />

        <Route exact path={`${path}/resources`} component={Resources} />
        <Route exact path={`${path}/resources/create`} component={CreateResources} />
        <Route exact path={`${path}/resources/edit/:id`} component={EditResources} />

        <Route exact path={`${path}/reports/contact`} component={Reports} />
        <Route exact path={`${path}/reports/order`} component={OrderReport} />
        <Route exact path={`${path}/reports/onlineorder`} component={OnlineOrderReport} />
        <Route exact path={`${path}/reports/message`} component={MessageReport} />
        <Route exact path={`${path}/reports/task`} component={TaskReport} />

        <Route exact path={`${path}/teams`} component={Teams} />
        <Route exact path={`${path}/organisation`} component={OrganisationList} />
        <Route exact path={`${path}/teams/member/:memberId`} component={TeamMember} />
        <Route exact path={`${path}/switch`} component={TeamSwitch} />
        {/* <Route exact path={`${path}/teams/message`} component={TeamsAddMessage} /> */}
        {/* <Route exact path={`${path}/pending-invitation`} component={PendingInvitation} />
        <Route exact path={`${path}/invite`} component={Invite} /> */}
        {/* <Route exact path={`${path}/inbox`} component={Inbox} />
        <Route exact path={`${path}/send-item`} component={SendItem} /> */}
        <Route exact path={`${path}/notes`} component={Notes} />
        <Route exact path={`${path}/search`} component={Search} />

        <Route exact path={`${path}/tags`} component={Tags} />
        <Route exact path={`${path}/tags/create`} component={CreateTag} />
        <Route exact path={`${path}/tags/edit/:id`} component={EditTag} />

        <Route exact path={`${path}/categories`} component={Categories} />
        <Route exact path={`${path}/categories/create`} component={CreateCategory} />
        <Route exact path={`${path}/categories/edit/:id`} component={EditCategory} />
        <Route exact path={`${path}/settings`} component={Settings} />
        <Route exact path={`${path}/domains`} component={Domain} />
        <Route exact path={`${path}/domains/add`} component={AddDomain} />
        <Route exact path={`${path}/domains/pointing`} component={PointDomain} />
        <Route exact path={`${path}/account`} component={Account} />
        <Route exact path={`${path}/change-password`} component={ChangePassword} />
        <Route exact path={`${path}/edit-profile`} component={EditProfile} />
        <Route exact path={`${path}/account/upgrade-subscription`} component={Upgrade} />
        <Route exact path={`${path}/account/checkout`} component={CheckOut} />
        <Route exact path={`${path}/integration`} component={Integration} />
        <Route exact path={`${path}/mailchimp-integration`} component={ApiIntegration} />

        <Route exact path={`${path}/notifications`} component={NotificationPage} />

        <Route exact path={`${path}/premium-feature`} component={PremiumFeature} />

        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  );
};
export default WithAdminLayout(Admin);

const actions = {
  TAG_CREATING_FROM_SELECT_BEGIN: 'TAG_CREATING_FROM_SELECT_BEGIN',
  TAG_CREATING_FROM_SELECT_SUCCESS: 'TAG_CREATING_FROM_SELECT_SUCCESS',
  TAG_CREATING_FROM_SELECT_ERR: 'TAG_CREATING_FROM_SELECT_ERR',
  TAG_CREATING_FROM_SELECT_CLOSE_MODEL: 'TAG_CREATING_FROM_SELECT_CLOSE_MODEL',
  CLEAR_SELECT_STATE: 'CLEAR_SELECT_STATE',

  TAG_FETCH_BEGIN: 'TAG_FETCH_BEGIN',
  TAG_FETCH_SUCCESS: 'TAG_FETCH_SUCCESS',
  TAG_FETCH_ERR: 'TAG_FETCH_ERR',

  TAG_CREATE_BEGIN: 'TAG_CREATE_BEGIN',
  TAG_CREATE_SUCCESS: 'TAG_CREATE_SUCCESS',
  TAG_CREATE_ERR: 'TAG_CREATE_ERR',

  TAG_DELETE_BEGIN: 'TAG_DELETE_BEGIN',
  TAG_DELETE_SUCCESS: 'TAG_DELETE_SUCCESS',
  TAG_DELETE_ERR: 'TAG_DELETE_ERR',

  TAG_BULK_DELETE_BEGIN: 'TAG_BULK_DELETE_BEGIN',
  TAG_BULK_DELETE_SUCCESS: 'TAG_BULK_DELETE_SUCCESS',
  TAG_BULK_DELETE_ERR: 'TAG_BULK_DELETE_ERR',

  TAG_UPDATE_BEGIN: 'TAG_UPDATE_BEGIN',
  TAG_UPDATE_SUCCESS: 'TAG_UPDATE_SUCCESS',
  TAG_UPDATE_ERR: 'TAG_UPDATE_ERR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  tagCreatingFromSelectBegin: (name) => ({
    type: actions.TAG_CREATING_FROM_SELECT_BEGIN,
    data: name,
  }),

  tagCreatingFromSelectSuccess: (data) => ({
    type: actions.TAG_CREATING_FROM_SELECT_SUCCESS,
    data,
  }),

  clearSelectState: () => ({
    type: actions.CLEAR_SELECT_STATE,
  }),

  tagCreatingFromSelectErr: (err) => ({
    type: actions.TAG_CREATING_FROM_SELECT_ERR,
    err,
  }),

  fetchTagsBegin: () => ({
    type: actions.TAG_FETCH_BEGIN,
  }),

  fetchTagsSuccess: (data) => ({
    type: actions.TAG_FETCH_SUCCESS,
    data,
  }),

  fetchTagsErr: (err) => ({
    type: actions.TAG_FETCH_ERR,
    err,
  }),

  tagCreateBegin: () => ({
    type: actions.TAG_CREATE_BEGIN,
  }),

  tagCreateSuccess: (data) => ({
    type: actions.TAG_CREATE_SUCCESS,
    data,
  }),

  tagCreateErr: (err) => ({
    type: actions.TAG_CREATE_ERR,
    err,
  }),

  tagDeleteBegin: () => ({
    type: actions.TAG_DELETE_BEGIN,
  }),

  tagDeleteSuccess: (data) => ({
    type: actions.TAG_DELETE_SUCCESS,
    data,
  }),

  tagDeleteErr: (err) => ({
    type: actions.TAG_DELETE_ERR,
    err,
  }),

  tagBulkDeleteBegin: () => ({
    type: actions.TAG_BULK_DELETE_BEGIN,
  }),

  tagBulkDeleteSuccess: (ids, data) => ({
    type: actions.TAG_BULK_DELETE_SUCCESS,
    ids,
    data,
  }),

  tagBulkDeleteErr: (err) => ({
    type: actions.TAG_BULK_DELETE_ERR,
    err,
  }),

  tagUpdateBegin: () => ({
    type: actions.TAG_UPDATE_BEGIN,
  }),

  tagUpdateSuccess: (data) => ({
    type: actions.TAG_UPDATE_SUCCESS,
    data,
  }),

  tagUpdateErr: (err) => ({
    type: actions.TAG_UPDATE_ERR,
    err,
  }),

  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
};

export default actions;

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => `${text.split(' ').slice(0, size).join(' ')}...`;
const capitalize = (str) => (str ? str.charAt(0).toUpperCase() + str.slice(1) : '');
const excerpt = (text, size) => {
  if (!text) {
    return '';
  }
  if (text.length > size) {
    return `${text.substring(0, size)}...`;
  }
  return text;
};

const slugify = (text) => {
  if (!text) {
    return '';
  }

  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '');
};

const generateColor = () => {
  const colors = ['E9ACB7', 'A8E681', '8185E6', 'DD667C', '4DB1B3'];
  const randomColor = colors[Math.floor(Math.random() * colors.length)];
  return `#${randomColor}`;
};

// Trim - from end of text
export { ellipsis, capitalize, excerpt, slugify, generateColor };

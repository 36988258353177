import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Avatar } from 'antd';
import { ReactSVG } from 'react-svg';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './style';
import Notification from './notification';
import { Popover } from '../../../components/popup';

import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../../components/heading/heading';
import useDeviceWidth from '../../../hooks/responsive';
import { excerpt } from '../../../utility/utility';

const AuthInfo = () => {
  const dispatch = useDispatch();
  const deviceWidth = useDeviceWidth();

  const user = JSON.parse(localStorage.getItem('user'));

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut());
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/admin/account">
              <ReactSVG src={require('../../../static/img/icons/account_header_black.svg').default} /> Account
            </Link>
          </li>

          {user?.plan_id !== 5 && user?.plan_id !== 6 && deviceWidth > 1080 && (
            <li>
              <Link to="/admin/account/upgrade-subscription">
                <ReactSVG src={require('../../../static/img/icons/upgrade.svg').default} /> Upgrade
              </Link>
            </li>
          )}
          {(user?.plan_id !== 1 && user?.plan_id) !== 2 && deviceWidth > 1080 && (
            <li>
              <Link to="/admin/integration">
                <FeatherIcon icon="share-2" size={20} /> Integration
              </Link>
            </li>
          )}
          {(deviceWidth > 1080 || (user?.plan_id === 5 && user?.plan_id === 6)) && (
            <li>
              <Link to="/admin/domains">
                <ReactSVG src={require('../../../static/img/icons/web.svg').default} />
                Domains
              </Link>
            </li>
          )}
          <li>
            <Link to="/admin/settings">
              <ReactSVG src={require('../../../static/img/icons/settings_header_black.svg').default} /> Settings
            </Link>
          </li>

          {/* <li>
            <Link to="#">
              <ReactSVG src={require('../../../static/img/icons/help_black.svg').default} /> Help
            </Link>
          </li> */}
          <li>
            <Link onClick={SignOut} to="#">
              <ReactSVG src={require('../../../static/img/icons/logout_header_black.svg').default} /> Logout
            </Link>
          </li>
        </ul>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <Notification />
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#">
            <figure className="user-dropdwon__info">
              <Avatar size="40" src={user?.img_url} />

              <figcaption>
                <Heading as="h5">{user?.full_name ? excerpt(user?.full_name, 22) : 'Login / Signup'}</Heading>
                <p>Welcome to Pamtree</p>
              </figcaption>

              {/* <ReactSVG src={require('../../../static/img/icons/arrow.svg').default} /> */}
            </figure>
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;

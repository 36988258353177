const actions = {
  MESSAGE_CREATE_BEGIN: 'MESSAGE_CREATE_BEGIN',
  MESSAGE_CREATE_SUCCESS: 'MESSAGE_CREATE_SUCCESS',
  MESSAGE_CREATE_ERR: 'MESSAGE_CREATE_ERR',

  MESSAGE_DELETE_BEGIN: 'MESSAGE_DELETE_BEGIN',
  MESSAGE_DELETE_SUCCESS: 'MESSAGE_DELETE_SUCCESS',
  MESSAGE_DELETE_ERR: 'MESSAGE_DELETE_ERR',

  MESSAGE_BULK_DELETE_BEGIN: 'MESSAGE_BULK_DELETE_BEGIN',
  MESSAGE_BULK_DELETE_SUCCESS: 'MESSAGE_BULK_DELETE_SUCCESS',
  MESSAGE_BULK_DELETE_ERR: 'MESSAGE_BULK_DELETE_ERR',

  MESSAGE_FETCH_BEGIN: 'MESSAGE_FETCH_BEGIN',
  MESSAGE_FETCH_SUCCESS: 'MESSAGE_FETCH_SUCCESS',
  MESSAGE_FETCH_ERR: 'MESSAGE_FETCH_ERR',

  MESSAGE_UPDATE_BEGIN: 'MESSAGE_UPDATE_BEGIN',
  MESSAGE_UPDATE_SUCCESS: 'MESSAGE_UPDATE_SUCCESS',
  MESSAGE_UPDATE_ERR: 'MESSAGE_UPDATE_ERR',

  MESSAGE_VIEW_BEGIN: 'MESSAGE_VIEW_BEGIN',
  MESSAGE_VIEW_SUCCESS: 'MESSAGE_VIEW_SUCCESS',
  MESSAGE_VIEW_ERR: 'MESSAGE_VIEW_ERR',

  CLEAR_ERROR: 'CLEAR_ERROR',
  CLEAR_SUCCESS: 'CLEAR_SUCCESS',

  // create
  messageCreatingBegin: () => ({
    type: actions.MESSAGE_CREATE_BEGIN,
  }),

  messageCreatingSuccess: (data) => ({
    type: actions.MESSAGE_CREATE_SUCCESS,
    data,
  }),

  messageCreatingErr: (err) => ({
    type: actions.MESSAGE_CREATE_ERR,
    err,
  }),

  // delete
  messageDeleteBegin: () => ({
    type: actions.MESSAGE_DELETE_BEGIN,
  }),

  messageDeleteSuccess: (data) => ({
    type: actions.MESSAGE_DELETE_SUCCESS,
    data,
  }),

  messageDeleteErr: (err) => ({
    type: actions.MESSAGE_DELETE_ERR,
    err,
  }),

  // bulk delete
  messageBulkDeleteBegin: () => ({
    type: actions.MESSAGE_BULK_DELETE_BEGIN,
  }),

  messageBulkDeleteSuccess: (data) => ({
    type: actions.MESSAGE_BULK_DELETE_SUCCESS,
    data,
  }),

  messageBulkDeleteErr: (err) => ({
    type: actions.MESSAGE_BULK_DELETE_ERR,
    err,
  }),

  // fetch
  messageFetchBegin: () => ({
    type: actions.MESSAGE_FETCH_BEGIN,
  }),

  messageFetchSuccess: (data) => ({
    type: actions.MESSAGE_FETCH_SUCCESS,
    data,
  }),

  messageFetchErr: (err) => ({
    type: actions.MESSAGE_FETCH_ERR,
    err,
  }),

  // update
  messageUpdateBegin: () => ({
    type: actions.MESSAGE_UPDATE_BEGIN,
  }),

  messageUpdateSuccess: (data) => ({
    type: actions.MESSAGE_UPDATE_SUCCESS,
    data,
  }),

  messageUpdateErr: (err) => ({
    type: actions.MESSAGE_UPDATE_ERR,
    err,
  }),

  // view
  messageViewBegin: () => ({
    type: actions.MESSAGE_VIEW_BEGIN,
  }),

  messageViewSuccess: (data) => ({
    type: actions.MESSAGE_VIEW_SUCCESS,
    data,
  }),

  messageViewErr: (err) => ({
    type: actions.MESSAGE_VIEW_ERR,
    err,
  }),

  // clear error
  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),

  clearSuccess: () => ({
    type: actions.CLEAR_SUCCESS,
  }),
};

export default actions;

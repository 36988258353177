const actions = {
  ADD_API_INTEGRATION_BEGIN: 'ADD_API_INTEGRATION_BEGIN',
  ADD_API_INTEGRATION_SUCCESS: 'ADD_API_INTEGRATION_SUCCESS',
  ADD_API_INTEGRATION_ERR: 'ADD_API_INTEGRATION_ERR',

  FETCH_API_INTEGRATIONS_BEGIN: 'FETCH_API_INTEGRATIONS_BEGIN',
  FETCH_API_INTEGRATIONS_SUCCESS: 'FETCH_API_INTEGRATIONS_SUCCESS',
  FETCH_API_INTEGRATIONS_ERR: 'FETCH_API_INTEGRATIONS_ERR',

  DELETE_API_INTEGRATION_BEGIN: 'DELETE_API_INTEGRATION_BEGIN',
  DELETE_API_INTEGRATION_SUCCESS: 'DELETE_API_INTEGRATION_SUCCESS',
  DELETE_API_INTEGRATION_ERR: 'DELETE_API_INTEGRATION_ERR',

  UPDATE_API_INTEGRATION_BEGIN: 'UPDATE_API_INTEGRATION_BEGIN',
  UPDATE_API_INTEGRATION_SUCCESS: 'UPDATE_API_INTEGRATION_SUCCESS',
  UPDATE_API_INTEGRATION_ERR: 'UPDATE_API_INTEGRATION_ERR',
  CLEAR_ERROR: 'CLEAR_ERROR',

  addApiIntegrationBegin: () => ({
    type: actions.ADD_API_INTEGRATION_BEGIN,
  }),

  addApiIntegrationSuccess: (data) => ({
    type: actions.ADD_API_INTEGRATION_SUCCESS,
    data,
  }),

  addApiIntegrationErr: (err) => ({
    type: actions.ADD_API_INTEGRATION_ERR,
    err,
  }),

  fetchApiIntegrationsBegin: () => ({
    type: actions.FETCH_API_INTEGRATIONS_BEGIN,
  }),

  fetchApiIntegrationsSuccess: (data) => ({
    type: actions.FETCH_API_INTEGRATIONS_SUCCESS,
    data,
  }),

  fetchApiIntegrationsErr: (err) => ({
    type: actions.FETCH_API_INTEGRATIONS_ERR,
    err,
  }),

  deleteApiIntegrationBegin: () => ({
    type: actions.DELETE_API_INTEGRATION_BEGIN,
  }),

  deleteApiIntegrationSuccess: (data) => ({
    type: actions.DELETE_API_INTEGRATION_SUCCESS,
    data,
  }),

  deleteApiIntegrationErr: (err) => ({
    type: actions.DELETE_API_INTEGRATION_ERR,
    err,
  }),
  updateApiIntegrationBegin: () => ({
    type: actions.UPDATE_API_INTEGRATION_BEGIN,
  }),
  updateApiIntegrationSuccess: (data) => ({
    type: actions.UPDATE_API_INTEGRATION_SUCCESS,

    data,
  }),
  updateApiIntegrationErr: (err) => ({
    type: actions.UPDATE_API_INTEGRATION_ERR,
    err,
  }),

  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
};

export default actions;

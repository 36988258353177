/* eslint-disable object-shorthand */
import React from 'react';
import { Menu, Input, Space } from 'antd';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { NavLink, useHistory } from 'react-router-dom';
import propTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import MenuItemComponent from './MenuItemComponent';
import useUserPlan from '../hooks/userPlan';
import useDeviceWidth from '../hooks/responsive';

const { SubMenu } = Menu;

const MenuItems = ({ toggleCollapsed }) => {
  const [openKeys, setOpenKeys] = React.useState(['sub1']);
  const deviceWidth = useDeviceWidth();

  const onOpenChange = (keys) => {
    const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const userPlan = useUserPlan();

  const sepclass = classNames('sep-menu', {
    bottommenu: window.innerHeight > 890,
  });

  const upgradeIcon = require('../static/img/icons/upgrade.svg').default;

  const menuItems = [
    {
      iconSrc: require('../static/img/icons/dashboard_black.svg').default,
      linkTo: '/admin',
      label: 'Dashboard',
      key: 'Dashboard',
      plan: ['free', 'business', 'premium'],
    },
    {
      iconSrc: require('../static/img/icons/contact_black.svg').default,
      linkTo: '/admin/contacts',
      label: 'Contacts',
      key: 'contacts',
      plan: ['free', 'business', 'premium'],
    },
    {
      iconSrc: require('../static/img/icons/message_black.svg').default,
      linkTo: '/admin/messages',
      label: 'Messages',
      key: 'messages',
      upgradeIcon: upgradeIcon,
      plan: ['business', 'premium'],
    },
    {
      iconSrc: require('../static/img/icons/todo_black.svg').default,
      linkTo: '/admin/todo-list',
      label: 'To-do List',
      key: 'todo',
      plan: ['free', 'business', 'premium'],
    },
    {
      iconSrc: require('../static/img/icons/salesfunnel_black.svg').default,
      linkTo: '/admin/sales-funnel',
      label: 'Sales Funnel',
      key: 'funnel',
      upgradeIcon: upgradeIcon,
      plan: ['free', 'business', 'premium'],
    },
    {
      iconSrc: require('../static/img/icons/customertracker_black.svg').default,
      linkTo: '/admin/customer-tracker',
      label: 'Customer Tracker',
      key: 'tracker',
      upgradeIcon: upgradeIcon,
      plan: ['premium'],
    },
    {
      iconSrc: require('../static/img/icons/product_black.svg').default,
      linkTo: '/admin/products',
      label: 'Products',
      key: 'product',
      upgradeIcon: upgradeIcon,
      plan: ['business', 'premium'],
    },
    {
      iconSrc: require('../static/img/icons/resources_black.svg').default,
      linkTo: '/admin/resources',
      label: 'Resources',
      key: 'resource',
      upgradeIcon: upgradeIcon,
      plan: ['business', 'premium'],
    },
    {
      iconSrc: require('../static/img/icons/reports_black.svg').default,
      linkTo: '/admin/reports/contact',
      label: 'Reports',
      key: 'report',
      upgradeIcon: upgradeIcon,
      plan: ['premium'],
    },
    {
      iconSrc: require('../static/img/icons/myteam_black.svg').default,
      linkTo: '/admin/teams',
      label: 'My Team',
      key: 'my-team',
      plan: ['free', 'business', 'premium'],
    },
    {
      iconSrc: require('../static/img/icons/notes_black.svg').default,
      linkTo: '/admin/notes',
      label: 'Notes',
      key: 'note',
      plan: ['free', 'business', 'premium'],
    },
    {
      iconSrc: require('../static/img/icons/tags_black.svg').default,
      linkTo: '/admin/tags',
      label: 'Tags',
      key: 'tags',
      plan: ['free', 'business', 'premium'],
    },
    {
      iconSrc: require('../static/img/icons/categories_black.svg').default,
      linkTo: '/admin/categories',
      label: 'Categories',
      key: 'category',
      plan: ['free', 'business', 'premium'],
    },
  ];
  const { Search } = Input;
  const history = useHistory();

  const onSearch = (values) => {
    // history.push(`/admin/search?q=${values}`);
    history.push({ pathname: '/admin/search', search: `?q=${values}` });
    toggleCollapsed();
  };

  return (
    <>
      <Space
        direction="vertical"
        style={{
          padding: '10px',
          width: '100%',
        }}
      >
        <Search placeholder="Search Pamtree " onSearch={onSearch} allowClear enterButton />
      </Space>

      <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange} style={{ width: 256 }}>
        {menuItems
          .filter((item) => (deviceWidth < 1080 ? item.plan.includes(userPlan) : true))
          .map((item) => (
            <MenuItemComponent key={item.key} {...item} toggleCollapsed={toggleCollapsed} userPlan={userPlan} />
          ))}
      </Menu>

      <Menu className={sepclass} mode="inline" openKeys={openKeys} onOpenChange={onOpenChange} style={{ width: 256 }}>
        <SubMenu
          key="info"
          icon={<ReactSVG src={require('../static/img/icons/information_black.svg').default} />}
          title="Information"
        >
          <Menu.Item key="inbox">
            <NavLink target="_blank" to={{ pathname: 'https://pamtree.com/user-agreement/' }}>
              User agreement
            </NavLink>
          </Menu.Item>
        </SubMenu>
        <Menu.Item icon={<FeatherIcon icon="help-circle" size={25} />} key="idea">
          <NavLink
            target="_blank"
            to={{ pathname: 'https://app.frill.co/embed/widget/1ab34ed4-fd30-4438-8ac3-7a67966815ca' }}
          >
            Submit an Idea
          </NavLink>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default MenuItems;

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

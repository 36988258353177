/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { Layout, Row, Col } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Div } from './style';
import MenuItems from './MenueItems';
import HeaderMenu from './HeaderMenu';
import RightMenu from './RightMenu';
import { Button } from '../components/buttons';

const { Header, Sider, Content } = Layout;

const withAdminLayout = (WrappedComponent) => {
  class LayoutComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: true,
        hide: true,
        searchHide: true,
        activeSearch: false,
        manuallyToggled: false,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed } = this.state;
      const { user } = this.props;
      const companyLogo = user?.company?.image;

      const SideBarStyle = {
        margin: window.innerWidth >= 768 ? '60px 0 0 0' : '90px 0 0 0',
        padding: '15px 15px 55px 15px',
        overflowY: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        zIndex: 998,
        background: '#F8F8F8',
        borderRight: '1px solid #E8E8E8',
      };

      const style = classNames('align-center-v navbar-brand', {
        mobile: collapsed,
      });

      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };

      const fullScreenClick = (e) => {
        e.preventDefault();
        const element = document.documentElement;
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }

        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      };

      return (
        <Div darkMode={false}>
          <Layout className="layout">
            <Header style={{ position: 'fixed', width: '100%', top: 0 }}>
              <Row>
                <Col className={style}>
                  <HeaderMenu companyLogo={companyLogo} />
                </Col>

                <Col flex="auto">
                  {window.innerWidth >= 1200 && (
                    <Row className="mt-13" align="middle">
                      <Button
                        className="btn-icon  mr-15 collapse-btn"
                        size="large"
                        shape="circle"
                        onClick={fullScreenClick}
                      >
                        <ReactSVG src={require('../static/img/icons/zoom.svg').default} />
                      </Button>
                    </Row>
                  )}
                  {/* <HeaderSearch /> */}
                </Col>

                <Col lg={6} md={10}>
                  <div className="text-right">
                    <RightMenu toggleCollapsed={toggleCollapsed} collapsed={collapsed} />
                  </div>
                </Col>
              </Row>
            </Header>
            <Layout>
              <Sider width={280} style={SideBarStyle} collapsed={collapsed} theme="light">
                <Scrollbars
                  className="custom-scrollbar"
                  autoHide
                  autoHideTimeout={500}
                  autoHideDuration={200}
                  // renderThumbHorizontal={renderThumbHorizontal}
                  // renderThumbVertical={renderThumbVertical}
                  // renderView={renderView}
                  // renderTrackVertical={renderTrackVertical}
                >
                  <MenuItems toggleCollapsed={toggleCollapsedMobile} />
                </Scrollbars>
              </Sider>
              <Layout className="atbd-main-layout">
                <Content>
                  <WrappedComponent {...this.props} />
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Div>
      );
    }
  }
  const mapStateToProps = (state) => ({
    user: state.auth.user,
  });

  const ConnectedLayoutComponent = connect(mapStateToProps)(LayoutComponent);

  return ConnectedLayoutComponent;
};

export default withAdminLayout;

/* eslint-disable default-param-last */
import actions from './actions';

const {
  MESSAGE_TEMPLATE_FETCH_BEGIN,
  MESSAGE_TEMPLATE_FETCH_SUCCESS,
  MESSAGE_TEMPLATE_FETCH_ERR,

  MESSAGE_TEMPLATE_CREATE_BEGIN,
  MESSAGE_TEMPLATE_CREATE_SUCCESS,
  MESSAGE_TEMPLATE_CREATE_ERR,

  MESSAGE_TEMPLATE_DELETE_BEGIN,
  MESSAGE_TEMPLATE_DELETE_SUCCESS,
  MESSAGE_TEMPLATE_DELETE_ERR,

  MESSAGE_TEMPLATE_UPDATE_BEGIN,
  MESSAGE_TEMPLATE_UPDATE_SUCCESS,
  MESSAGE_TEMPLATE_UPDATE_ERR,

  MESSAGE_TEMPLATE_VIEW_BEGIN,
  MESSAGE_TEMPLATE_VIEW_SUCCESS,
  MESSAGE_TEMPLATE_VIEW_ERR,

  MESSAGE_TEMPLATE_BULK_DELETE_BEGIN,
  MESSAGE_TEMPLATE_BULK_DELETE_SUCCESS,
  MESSAGE_TEMPLATE_BULK_DELETE_ERR,

  CLEAR_ERROR,
  CLEAR_ERROR_MESSAGE,
} = actions;

const initialState = {
  messageTemplates: [],
  messageTemplate: null,
  error: null,
  loading: false,
  count: 0,
};

const messageTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_TEMPLATE_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_TEMPLATE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        messageTemplates: action.data.data,
        count: action.data.total,
      };
    case MESSAGE_TEMPLATE_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MESSAGE_TEMPLATE_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        messageTemplates: [...state.messageTemplates, action.data],
      };
    case MESSAGE_TEMPLATE_CREATE_ERR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MESSAGE_TEMPLATE_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_TEMPLATE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        messageTemplates: state.messageTemplates.filter((messageTemplate) => messageTemplate.id !== action.data.id),
      };
    case MESSAGE_TEMPLATE_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MESSAGE_TEMPLATE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_TEMPLATE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        messageTemplates: state.messageTemplates.map((messageTemplate) => {
          if (messageTemplate.id === action.data.id) {
            return action.data;
          }
          return messageTemplate;
        }),
      };
    case MESSAGE_TEMPLATE_UPDATE_ERR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case MESSAGE_TEMPLATE_VIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_TEMPLATE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        messageTemplate: action.data,
      };
    case MESSAGE_TEMPLATE_VIEW_ERR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MESSAGE_TEMPLATE_BULK_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_TEMPLATE_BULK_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        messageTemplates: state.messageTemplates.filter((messageTemplate) => !action.data.includes(messageTemplate.id)),
      };
    case MESSAGE_TEMPLATE_BULK_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        messageTemplate: null,
      };
    default:
      return state;
  }
};

export default messageTemplateReducer;

import actions from './actions';

const {
  NOTE_CREATE_BEGIN,
  NOTE_CREATE_SUCCESS,
  NOTE_CREATE_ERR,

  NOTE_DELETE_BEGIN,
  NOTE_DELETE_SUCCESS,
  NOTE_DELETE_ERR,

  NOTE_UPDATE_BEGIN,
  NOTE_UPDATE_SUCCESS,
  NOTE_UPDATE_ERR,

  NOTE_SHOW_BEGIN,
  NOTE_SHOW_SUCCESS,
  NOTE_SHOW_ERR,

  NOTES_FETCH_BEGIN,
  NOTES_FETCH_SUCCESS,
  NOTES_FETCH_ERR,

  NOTE_CLEAR_ERROR,
} = actions;

const initialState = {
  loading: false,
  error: null,
  notes: [],
  note: {},
  count: 0,
};

// eslint-disable-next-line default-param-last
const NoteReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case NOTE_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case NOTE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notes: [...state.notes, data],
      };
    case NOTE_CREATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case NOTE_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case NOTE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        notes: state.notes.filter((note) => note.id !== data),
      };
    case NOTE_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case NOTE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case NOTE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notes: state.notes.map((note) => {
          if (note.id === data.id) {
            return data;
          }
          return note;
        }),
      };
    case NOTE_UPDATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case NOTE_SHOW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case NOTE_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        note: data,
      };
    case NOTE_SHOW_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case NOTES_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case NOTES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        notes: data.data,
        count: data.total,
      };
    case NOTES_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case NOTE_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default NoteReducer;

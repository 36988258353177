const actionTypes = {
  BEGIN: 'BEGIN',
  SUCCESS: 'SUCCESS',
  ERR: 'ERR',
  CLEAR_ERROR: 'CLEAR_ERROR',
};

const createAsyncActionTypes = (base) => ({
  BEGIN: `${base}_BEGIN`,
  SUCCESS: `${base}_SUCCESS`,
  ERR: `${base}_ERR`,
});

const onlineOrderActions = {
  FETCH: createAsyncActionTypes('ONLINE_ORDER_FETCH'),
  CREATE: createAsyncActionTypes('ONLINE_ORDER_CREATE'),
  DELETE: createAsyncActionTypes('ONLINE_ORDER_DELETE'),
  BULK_DELETE: createAsyncActionTypes('ONLINE_ORDER_BULK_DELETE'),
  UPDATE: createAsyncActionTypes('ONLINE_ORDER_UPDATE'),
  VIEW: createAsyncActionTypes('ONLINE_ORDER_VIEW'),
  CLEAR_ERROR: actionTypes.CLEAR_ERROR,
};

const createAction = (type, payload) => ({ type, ...payload });

const createAsyncActions = (actions) => ({
  begin: () => createAction(actions.BEGIN),
  success: (data) => createAction(actions.SUCCESS, { data }),
  err: (err) => createAction(actions.ERR, { err }),
});

const onlineOrderActionCreators = {
  fetch: createAsyncActions(onlineOrderActions.FETCH),
  create: createAsyncActions(onlineOrderActions.CREATE),
  delete: createAsyncActions(onlineOrderActions.DELETE),
  bulkDelete: createAsyncActions(onlineOrderActions.BULK_DELETE),
  update: createAsyncActions(onlineOrderActions.UPDATE),
  view: createAsyncActions(onlineOrderActions.VIEW),
  clearError: () => createAction(onlineOrderActions.CLEAR_ERROR),
};

export { actionTypes, onlineOrderActions, onlineOrderActionCreators };

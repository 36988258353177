/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { Router, Redirect, Route } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import history from './history';
import Auth from './routes/auth';
import VerificationRoutes from './routes/verification';
import IntercomComponent from './utility/Intercom';
import Admin from './routes/admin';
import ProtectedRoute from './components/utilities/protectedRoute';
import store from './redux/store';
import 'react-telephone-input/css/default.css';
import './App.less';

import { usePageView, useUserData, useUsermaven } from './useUsernames';
import { DataService } from './config/dataService';
import useDeviceWidth from './hooks/responsive';

const ADMIN_PATH = '/admin';

function ProviderConfig() {
  const isLoggedIn = useSelector((state) => state.auth.login);
  const verified = useSelector((state) => state.auth.verify);

  const [path, setPath] = useState(window.location.pathname);
  const [query, setQuery] = useState(window.location.search);

  const adminUrl = path.split('/')[1];

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
      setQuery(window.location.search);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  const usermavenOpts = {
    key: 'UMvtRFlmTM',
    tracking_host: 'https://events.usermaven.com',
  };

  const { usermaven } = useUsermaven(usermavenOpts);

  usePageView(usermaven);
  useUserData(usermaven, isLoggedIn);

  useEffect(() => {
    ReactPixel.init('353487822168785', {
      autoConfig: true,
      debug: false,
    });

    ReactPixel.pageView();

    DataService.post('/fb-pixel-event', {
      event: 'PageView',
      url: window.location.href,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL} history={history}>
      {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path={ADMIN_PATH} component={Admin} />}

      {isLoggedIn && !verified && <ProtectedRoute path="/" component={VerificationRoutes} />}

      {isLoggedIn && verified && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
        <Redirect to={ADMIN_PATH} />
      )}
      {isLoggedIn && verified && path === '/join-pamtree' && (
        <Redirect
          to={{
            pathname: `${ADMIN_PATH}/switch`,
            search: query,
          }}
        />
      )}

      {isLoggedIn && !verified && path !== '/verifying' && <Redirect to="/verify" />}
      {isLoggedIn && verified && adminUrl !== 'admin' && <Redirect to={ADMIN_PATH} />}
    </Router>
  );
}

function App() {
  const deviceWidth = useDeviceWidth();
  return (
    <Provider store={store}>
      {deviceWidth > 1080 && <IntercomComponent />}
      <ProviderConfig />
    </Provider>
  );
}

export default App;

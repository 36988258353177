function useUserPlan() {
  const user = JSON.parse(localStorage.getItem('user'));

  switch (user.plan_id) {
    case 1:
      return 'free';
    case 2:
      return 'free';
    case 3:
      return 'business';
    case 4:
      return 'business';
    case 5:
      return 'premium';
    case 6:
      return 'premium';
    default:
      return 'free';
  }
}

export default useUserPlan;

import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const SideMenu = ({ toggleCollapsed, collapsed }) => (
  <Link onClick={toggleCollapsed} className={collapsed ? 'mobile-logo logosite' : 'pam-logo logosite'}>
    <FeatherIcon icon="menu" size={25} />
  </Link>
);

export default SideMenu;

SideMenu.propTypes = {
  toggleCollapsed: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
};

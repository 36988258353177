/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import Echo from 'laravel-echo';
import { Badge, Row, Col, Avatar, Spin, Empty } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import parse from 'html-react-parser';
import { Scrollbars } from 'react-custom-scrollbars';
import Pusher from 'pusher-js';
import { useSelector, useDispatch } from 'react-redux';
import { AtbdTopDropdwon } from './style';
import NotificationIcon from '../../notifications/NotificationIcon';
import { Button } from '../../../components/buttons';
import Heading from '../../../components/heading/heading';
import { Popover } from '../../../components/popup';
import { getItem } from '../../../utility/localStorageControl';
import { fetchRecentNotifications, fetchNotifications, markAsRead } from '../../../redux/notifications/actionCreator';

const NotificationBox = () => {
  const { id } = getItem('user');

  const { recentNotifications, count, loadingRecent } = useSelector((state) => state.notifications);

  const dispatch = useDispatch();
  window.pusher = Pusher;

  const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const options = {
      broadcaster: 'pusher',
      key: '82696a8255ccf7154b08',
      cluster: 'eu',
      // forceTLS: config.pusher.tls,
      authEndpoint: `${API_ENDPOINT}/broadcasting/auth`,
      encrypted: true,
      auth: {
        headers: {
          Authorization: `Bearer ${getItem('access_token')}`,
          Accept: 'application/json',
        },
      },
    };

    const echo = new Echo(options);

    if (id) {
      echo.private(`App.Models.User.${id}`).notification((notification) => {
        dispatch(fetchRecentNotifications());
        dispatch(fetchNotifications());
      });
    }
  }, []);

  useEffect(() => {
    dispatch(fetchRecentNotifications());
  }, []);

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} props={props} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      right: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  };

  const renderView = ({ style, ...props }) => {
    const customStyle = {
      marginRight: '-17px',
    };
    return <div {...props} style={{ ...style, ...customStyle }} />;
  };

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  // const newYork = moment.tz('2014-06-01 12:00', 'America/New_York');

  const content = (
    <AtbdTopDropdwon className="atbd-top-dropdwon">
      <Row className="mb-20" align="middle">
        <Col flex="auto">
          <Heading className="m-0" as="h4">
            <span className="title-text">Notifications</span>
          </Heading>
        </Col>
        <Col>
          <Row gutter={8}>
            <Col>
              <Link className="link-button light" to="/admin/notifications">
                View All
              </Link>
            </Col>
            <Col>
              <Button
                className="btn-icon auto light"
                shape="circle"
                size="small"
                outlined
                type="primary"
                onClick={() => {
                  dispatch(fetchRecentNotifications());
                }}
              >
                <FeatherIcon icon="refresh-ccw" size={10} />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Scrollbars
        autoHeight
        autoHide
        autoHeightMin={250}
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        {loadingRecent ? (
          <div className="text-center w100p">
            <Spin />
          </div>
        ) : (
          <ul className="atbd-top-dropdwon__nav notification-list">
            {recentNotifications.length > 0 ? (
              recentNotifications.map((notification) => (
                <li key={notification.id}>
                  <Link
                    onClick={() => {
                      dispatch(markAsRead(notification.id));
                    }}
                    to={notification.data.link}
                  >
                    <div className="atbd-top-dropdwon__content notifications">
                      <Badge dot={!notification.read_at ?? true}>
                        <Avatar
                          size={35}
                          className="notification-icon"
                          icon={<NotificationIcon type={notification.type} />}
                        />
                      </Badge>
                      <div className="notification-content d-flex">
                        <div className="notification-text">
                          <Heading as="h6">{parse(notification.data.message)}</Heading>
                          <p>{moment(notification.created_at).fromNow()}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </li>
              ))
            ) : (
              <Empty description={<span>You have no new notifications</span>} />
            )}
          </ul>
        )}
      </Scrollbars>
    </AtbdTopDropdwon>
  );

  return (
    <div className="notification mr-15">
      <Popover placement="bottomLeft" content={content} action="click">
        <Badge className="badge-success" count={count} offset={[-13, -4]}>
          <Link to="#" className="head-example">
            <FeatherIcon icon="bell" size={25} />
          </Link>
        </Badge>
      </Popover>
    </div>
  );
};

export default NotificationBox;

const actions = {
  TEMPLATE_FETCH_BEGIN: 'TEMPLATE_FETCH_BEGIN',
  TEMPLATE_FETCH_SUCCESS: 'TEMPLATE_FETCH_SUCCESS',
  TEMPLATE_FETCH_ERR: 'TEMPLATE_FETCH_ERR',

  TEMPLATE_CREATE_BEGIN: 'TEMPLATE_CREATE_BEGIN',
  TEMPLATE_CREATE_SUCCESS: 'TEMPLATE_CREATE_SUCCESS',
  TEMPLATE_CREATE_ERR: 'TEMPLATE_CREATE_ERR',

  TEMPLATE_DELETE_BEGIN: 'TEMPLATE_DELETE_BEGIN',
  TEMPLATE_DELETE_SUCCESS: 'TEMPLATE_DELETE_SUCCESS',
  TEMPLATE_DELETE_ERR: 'TEMPLATE_DELETE_ERR',

  TEMPLATE_UPDATE_BEGIN: 'TEMPLATE_UPDATE_BEGIN',
  TEMPLATE_UPDATE_SUCCESS: 'TEMPLATE_UPDATE_SUCCESS',
  TEMPLATE_UPDATE_ERR: 'TEMPLATE_UPDATE_ERR',

  TEMPLATE_VIEW_BEGIN: 'TEMPLATE_VIEW_BEGIN',
  TEMPLATE_VIEW_SUCCESS: 'TEMPLATE_VIEW_SUCCESS',
  TEMPLATE_VIEW_ERR: 'TEMPLATE_VIEW_ERR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  templateFetchBegin: () => ({
    type: actions.TEMPLATE_FETCH_BEGIN,
  }),

  templateFetchSuccess: (data) => ({
    type: actions.TEMPLATE_FETCH_SUCCESS,
    data,
  }),

  templateFetchErr: (err) => ({
    type: actions.TEMPLATE_FETCH_ERR,
    err,
  }),

  templateCreateBegin: () => ({
    type: actions.TEMPLATE_CREATE_BEGIN,
  }),

  templateCreateSuccess: (data) => ({
    type: actions.TEMPLATE_CREATE_SUCCESS,
    data,
  }),

  templateCreateErr: (err) => ({
    type: actions.TEMPLATE_CREATE_ERR,
    err,
  }),

  templateDeleteBegin: () => ({
    type: actions.TEMPLATE_DELETE_BEGIN,
  }),

  templateDeleteSuccess: (data) => ({
    type: actions.TEMPLATE_DELETE_SUCCESS,
    data,
  }),

  templateDeleteErr: (err) => ({
    type: actions.TEMPLATE_DELETE_ERR,
    err,
  }),

  templateUpdateBegin: () => ({
    type: actions.TEMPLATE_UPDATE_BEGIN,
  }),

  templateUpdateSuccess: (data) => ({
    type: actions.TEMPLATE_UPDATE_SUCCESS,
    data,
  }),

  templateUpdateErr: (err) => ({
    type: actions.TEMPLATE_UPDATE_ERR,
    err,
  }),

  templateViewBegin: () => ({
    type: actions.TEMPLATE_VIEW_BEGIN,
  }),

  templateViewSuccess: (data) => ({
    type: actions.TEMPLATE_VIEW_SUCCESS,
    data,
  }),

  templateViewErr: (err) => ({
    type: actions.TEMPLATE_VIEW_ERR,
    err,
  }),

  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
};
export default actions;

/* eslint-disable default-param-last */
import actions from './actions';

const {
  MESSAGE_CREATE_BEGIN,
  MESSAGE_CREATE_SUCCESS,
  MESSAGE_CREATE_ERR,

  MESSAGE_DELETE_BEGIN,
  MESSAGE_DELETE_SUCCESS,
  MESSAGE_DELETE_ERR,

  MESSAGE_BULK_DELETE_BEGIN,
  MESSAGE_BULK_DELETE_SUCCESS,
  MESSAGE_BULK_DELETE_ERR,

  MESSAGE_FETCH_BEGIN,
  MESSAGE_FETCH_SUCCESS,
  MESSAGE_FETCH_ERR,

  MESSAGE_UPDATE_BEGIN,
  MESSAGE_UPDATE_SUCCESS,
  MESSAGE_UPDATE_ERR,

  MESSAGE_VIEW_BEGIN,
  MESSAGE_VIEW_SUCCESS,
  MESSAGE_VIEW_ERR,

  CLEAR_ERROR,
  CLEAR_SUCCESS,
} = actions;

const initialState = {
  messages: [],
  message: {},
  error: null,
  messageSuccess: null,
  loading: false,
  count: 0,
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: [...state.messages, action.data],
        messageSuccess: action.data,
      };
    case MESSAGE_CREATE_ERR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MESSAGE_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: state.messages.filter((message) => message.id !== action.data),
      };
    case MESSAGE_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MESSAGE_BULK_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_BULK_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: state.messages.filter((message) => !action.data.includes(message.id)),
      };
    case MESSAGE_BULK_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case MESSAGE_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: action.data.data,
        count: action.data.total,
      };
    case MESSAGE_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: action.err,
      };
    case MESSAGE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        messages: state.messages.map((message) => (message.id === action.data.id ? action.data : message)),
      };
    case MESSAGE_UPDATE_ERR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case MESSAGE_VIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        message: state.messages.map((message) => (message.id === action.data.id ? action.data : message)),
      };
    case MESSAGE_VIEW_ERR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_SUCCESS:
      return {
        ...state,
        messageSuccess: null,
      };
    default:
      return state;
  }
};

export default messageReducer;

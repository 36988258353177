import actions from './actions';

const {
  PRODUCT_CREATING_FROM_SELECT_SUCCESS,
  PRODUCT_CREATING_FROM_SELECT_ERR,
  PRODUCT_CREATING_FROM_SELECT_BEGIN,
  PRODUCT_CREATING_FROM_SELECT_CLOSE_MODEL,
  CLEAR_SELECT_STATE,

  PRODUCT_FETCH_BEGIN,
  PRODUCT_FETCH_SUCCESS,
  PRODUCT_FETCH_ERR,

  PRODUCT_CREATE_BEGIN,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_ERR,

  PRODUCT_DELETE_BEGIN,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_ERR,

  PRODUCT_BULK_DELETE_BEGIN,
  PRODUCT_BULK_DELETE_SUCCESS,
  PRODUCT_BULK_DELETE_ERR,

  PRODUCT_UPDATE_BEGIN,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_ERR,

  PRODUCT_VIEW_BEGIN,
  PRODUCT_VIEW_SUCCESS,
  PRODUCT_VIEW_ERR,

  CLEAR_ERROR,
} = actions;

const initState = {
  products: [],
  product: {},
  error: null,
  searchResult: [],
  loading: false,
  count: 0,
  isAddingProduct: false,
  createdFromSelectProduct: [],
  selectCreatingProductName: '',
};

// eslint-disable-next-line default-param-last
const ProductReducer = (state = initState, action) => {
  const { type, data, err, ids } = action;
  switch (type) {
    case PRODUCT_CREATING_FROM_SELECT_BEGIN:
      return {
        ...state,
        isAddingProduct: true,
        selectCreatingProductName: data,
      };
    case PRODUCT_CREATING_FROM_SELECT_SUCCESS:
      return {
        ...state,
        createdFromSelectProduct: data,
        isAddingProduct: false,
      };
    case PRODUCT_CREATING_FROM_SELECT_ERR:
      return {
        ...state,
        error: err,
      };
    case PRODUCT_CREATING_FROM_SELECT_CLOSE_MODEL:
      return {
        ...state,
        selectCreatingProductName: '',
        isAddingProduct: false,
      };
    case CLEAR_SELECT_STATE:
      return {
        ...state,
        createdFromSelectProduct: [],
        selectCreatingProductName: '',
      };
    case PRODUCT_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case PRODUCT_FETCH_SUCCESS:
      return {
        ...state,
        products: data.data,
        loading: false,
        count: data.total,
      };

    case PRODUCT_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };

    case PRODUCT_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        products: [...state.products, data],
      };
    case PRODUCT_CREATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case PRODUCT_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        products: state.products.filter((product) => product.id !== data),
      };
    case PRODUCT_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case PRODUCT_BULK_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_BULK_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        products: state.products.filter((product) => !ids.includes(product.id)),
        bulkMessage: data,
      };
    case PRODUCT_BULK_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case PRODUCT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        products: state.products.map((product) => {
          if (product.id === data.id) {
            return data;
          }
          return product;
        }),
      };
    case PRODUCT_UPDATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case PRODUCT_VIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        product: data,
      };
    case PRODUCT_VIEW_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export default ProductReducer;

/* eslint-disable default-param-last */
import actions from './actions';

const {
  FETCH_ACCOUNTS_BEGIN,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_ACCOUNTS_ERR,

  ADD_TO_ACCOUNT_BEGIN,
  ADD_TO_ACCOUNT_SUCCESS,
  ADD_TO_ACCOUNT_ERR,

  FETCH_ACTIVITY_BEGIN,
  FETCH_ACTIVITY_SUCCESS,
  FETCH_ACTIVITY_ERR,

  FETCH_ACCOUNTS_LIST_BEGIN,
  FETCH_ACCOUNTS_LIST_SUCCESS,
  FETCH_ACCOUNTS_LIST_ERR,
} = actions;

const initState = {
  accounts: [],
  account: {},
  lists: [],
  activities: [],
  loading: false,
  error: null,
  message: '',
  messageStatus: '',
};

const MailchimpReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FETCH_ACCOUNTS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        accounts: data.data,
      };
    case FETCH_ACCOUNTS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case ADD_TO_ACCOUNT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ADD_TO_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        account: data.data,
      };
    case ADD_TO_ACCOUNT_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case FETCH_ACTIVITY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        activities: data.data?.activity,
      };
    case FETCH_ACTIVITY_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case FETCH_ACCOUNTS_LIST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACCOUNTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        lists: data,
      };
    case FETCH_ACCOUNTS_LIST_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    default:
      return state;
  }
};

export default MailchimpReducer;

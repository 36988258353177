/* eslint-disable default-param-last */

import actions from './actions';

const {
  ADD_API_INTEGRATION_BEGIN,
  ADD_API_INTEGRATION_SUCCESS,
  ADD_API_INTEGRATION_ERR,
  FETCH_API_INTEGRATIONS_BEGIN,
  FETCH_API_INTEGRATIONS_SUCCESS,
  FETCH_API_INTEGRATIONS_ERR,

  DELETE_API_INTEGRATION_BEGIN,
  DELETE_API_INTEGRATION_SUCCESS,
  DELETE_API_INTEGRATION_ERR,

  UPDATE_API_INTEGRATION_BEGIN,
  UPDATE_API_INTEGRATION_SUCCESS,
  UPDATE_API_INTEGRATION_ERR,

  CLEAR_ERROR,
} = actions;

const initState = {
  integrations: [],
  isOpen: false,
  error: null,
  loading: false,
};

const IntegrationReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ADD_API_INTEGRATION_BEGIN:
      return {
        ...state,
        loading: true,
        isOpen: data,
      };
    case ADD_API_INTEGRATION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
        isOpen: false,
      };
    case ADD_API_INTEGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isOpen: false,
        integrations: [...state.integrations, data.data],
      };
    case FETCH_API_INTEGRATIONS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_API_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        integrations: data.data,
      };
    case FETCH_API_INTEGRATIONS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_API_INTEGRATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_API_INTEGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        integrations: state.integrations.filter((integration) => integration.id !== data.data.id),
      };
    case DELETE_API_INTEGRATION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case UPDATE_API_INTEGRATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_API_INTEGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        integrations: state.integrations.map((integration) => {
          if (integration.id === data.data.id) {
            return data.data;
          }
          return integration;
        }),
      };
    case UPDATE_API_INTEGRATION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default IntegrationReducer;

import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { PropTypes } from 'prop-types';

const NotificationIcon = ({ type }) => {
  switch (type) {
    case 'App\\Notifications\\MessageLinkClicked':
      return <FeatherIcon size={14} icon="mail" />;
    case 'App\\Notifications\\SharedTagDeleted':
    case 'App\\Notifications\\TagShared':
    case 'App\\Notifications\\SharedTagUpdated':
      return <FeatherIcon size={14} icon="tag" />;
    case 'App\\Notifications\\CategoryShared':
    case 'App\\Notifications\\SharedCategoryUpdated':
    case 'App\\Notifications\\SharedCategoryDeleted':
      return <FeatherIcon size={14} icon="grid" />;
    case 'App\\Notifications\\ProductShared':
    case 'App\\Notifications\\SharedProductUpdated':
    case 'App\\Notifications\\SharedProductDeleted':
      return <FeatherIcon size={14} icon="box" />;
    case 'App\\Notifications\\ResourceShared':
    case 'App\\Notifications\\SharedResourceUpdated':
    case 'App\\Notifications\\SharedResourceDeleted':
      return <FeatherIcon size={14} icon="sun" />;
    case 'App\\Notifications\\SharedTodoDeleted':
    case 'App\\Notifications\\TodoShared':
    case 'App\\Notifications\\SharedTodoUpdated':
      return <FeatherIcon size={14} icon="check-circle" />;
    default:
      return <FeatherIcon size={14} icon="bell" />;
  }
};

export default NotificationIcon;

NotificationIcon.propTypes = {
  type: PropTypes.string,
};

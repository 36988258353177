import React from 'react';
import { Row, Col } from 'antd';

import Footer from './overview/Footer';
import { LayoutWrapper } from './overview/style';
import TopNav from './overview/TopNav';

const AuthLayout = (WrapperContent) => () =>
  (
    <LayoutWrapper>
      <TopNav />
      <Row>
        <Col xs={24}>
          <WrapperContent />
        </Col>
        <Footer />
      </Row>
    </LayoutWrapper>
  );

export default AuthLayout;

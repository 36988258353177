import actions from './actions';

const {
  TAG_CREATING_FROM_SELECT_SUCCESS,
  TAG_CREATING_FROM_SELECT_ERR,
  TAG_CREATING_FROM_SELECT_BEGIN,
  TAG_CREATING_FROM_SELECT_CLOSE_MODEL,
  CLEAR_SELECT_STATE,
  TAG_FETCH_BEGIN,
  TAG_FETCH_SUCCESS,
  TAG_FETCH_ERR,
  TAG_CREATE_BEGIN,
  TAG_CREATE_SUCCESS,
  TAG_CREATE_ERR,
  TAG_DELETE_BEGIN,
  TAG_DELETE_SUCCESS,
  TAG_DELETE_ERR,
  TAG_UPDATE_BEGIN,
  TAG_UPDATE_SUCCESS,
  TAG_UPDATE_ERR,
  CLEAR_ERROR,
  TAG_BULK_DELETE_BEGIN,
  TAG_BULK_DELETE_SUCCESS,
  TAG_BULK_DELETE_ERR,
} = actions;

const initState = {
  tags: [],
  searchResult: [],
  count: 0,
  error: null,
  loading: false,
  isAddingTag: false,
  createdFromSelectTag: [],
  selectCreatingTagName: '',
  bulkMessage: '',
};

// eslint-disable-next-line default-param-last
const tagReducer = (state = initState, action) => {
  const { type, data, err, ids } = action;
  switch (type) {
    case TAG_CREATING_FROM_SELECT_BEGIN:
      return {
        ...state,
        isAddingTag: true,
        selectCreatingTagName: data,
      };
    case TAG_CREATING_FROM_SELECT_SUCCESS:
      return {
        ...state,
        createdFromSelectTag: data,
        isAddingTag: false,
      };
    case TAG_CREATING_FROM_SELECT_ERR:
      return {
        ...state,
        error: err,
        isAddingTag: false,
      };
    case TAG_CREATING_FROM_SELECT_CLOSE_MODEL:
      return {
        ...state,
        selectCreatingTagName: '',
        isAddingTag: false,
      };
    case CLEAR_SELECT_STATE:
      return {
        ...state,
        createdFromSelectTag: [],
        selectCreatingTagName: '',
      };
    case TAG_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case TAG_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: data.data,
        count: data.total,
      };
    case TAG_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case TAG_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TAG_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: [...state.tags, data],
      };
    case TAG_CREATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case TAG_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TAG_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: state.tags.filter((tag) => tag.id !== data),
      };
    case TAG_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case TAG_BULK_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TAG_BULK_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: state.tags.filter((tag) => !ids.includes(tag.id)),
        bulkMessage: data,
      };
    case TAG_BULK_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case TAG_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TAG_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: state.tags.map((tag) => {
          if (tag.id === data.id) {
            return data;
          }
          return tag;
        }),
      };
    case TAG_UPDATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        bulkMessage: '',
      };
    default:
      return state;
  }
};

export default tagReducer;

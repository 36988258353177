const actions = {
  LABEL_CREATE_BEGIN: 'LABEL_CREATE_BEGIN',
  LABEL_CREATE_SUCCESS: 'LABEL_CREATE_SUCCESS',
  LABEL_CREATE_ERR: 'LABEL_CREATE_ERR',

  LABEL_DELETE_BEGIN: 'LABEL_DELETE_BEGIN',
  LABEL_DELETE_SUCCESS: 'LABEL_DELETE_SUCCESS',
  LABEL_DELETE_ERR: 'LABEL_DELETE_ERR',

  // fetch
  LABEL_FETCH_BEGIN: 'LABEL_FETCH_BEGIN',
  LABEL_FETCH_SUCCESS: 'LABEL_FETCH_SUCCESS',
  LABEL_FETCH_ERR: 'LABEL_FETCH_ERR',

  // update
  LABEL_UPDATE_BEGIN: 'LABEL_UPDATE_BEGIN',
  LABEL_UPDATE_SUCCESS: 'LABEL_UPDATE_SUCCESS',
  LABEL_UPDATE_ERR: 'LABEL_UPDATE_ERR',

  // show
  LABEL_SHOW_BEGIN: 'LABEL_SHOW_BEGIN',
  LABEL_SHOW_SUCCESS: 'LABEL_SHOW_SUCCESS',
  LABEL_SHOW_ERR: 'LABEL_SHOW_ERR',

  // clear error
  LABEL_CLEAR_ERROR: 'LABEL_CLEAR_ERROR',

  labelCreateBegin: () => ({
    type: actions.LABEL_CREATE_BEGIN,
  }),

  labelCreateSuccess: (data) => ({
    type: actions.LABEL_CREATE_SUCCESS,
    data,
  }),

  labelCreateErr: (err) => ({
    type: actions.LABEL_CREATE_ERR,
    err,
  }),

  labelDeleteBegin: () => ({
    type: actions.LABEL_DELETE_BEGIN,
  }),

  labelDeleteSuccess: (data) => ({
    type: actions.LABEL_DELETE_SUCCESS,
    data,
  }),

  labelDeleteErr: (err) => ({
    type: actions.LABEL_DELETE_ERR,
    err,
  }),

  labelFetchBegin: () => ({
    type: actions.LABEL_FETCH_BEGIN,
  }),

  labelFetchSuccess: (data) => ({
    type: actions.LABEL_FETCH_SUCCESS,
    data,
  }),

  labelFetchErr: (err) => ({
    type: actions.LABEL_FETCH_ERR,
    err,
  }),

  labelUpdateBegin: () => ({
    type: actions.LABEL_UPDATE_BEGIN,
  }),

  labelUpdateSuccess: (data) => ({
    type: actions.LABEL_UPDATE_SUCCESS,
    data,
  }),

  labelUpdateErr: (err) => ({
    type: actions.LABEL_UPDATE_ERR,
    err,
  }),

  labelShowBegin: () => ({
    type: actions.LABEL_SHOW_BEGIN,
  }),

  labelShowSuccess: (data) => ({
    type: actions.LABEL_SHOW_SUCCESS,
    data,
  }),

  labelShowErr: (err) => ({
    type: actions.LABEL_SHOW_ERR,
    err,
  }),
};

export default actions;

import { onlineOrderActions } from './onlineActions';

const initState = {
  loading: false,
  error: null,
  onlineOrders: [],
  onlineOrder: {},
  count: 0,
  creditScore: 0,
};

const setLoading = (state, isLoading) => ({ ...state, loading: isLoading });
const setError = (state, error) => ({ ...state, error });

// eslint-disable-next-line default-param-last
const OnlineOrderReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case onlineOrderActions.FETCH.BEGIN:
    case onlineOrderActions.CREATE.BEGIN:
    case onlineOrderActions.DELETE.BEGIN:
    case onlineOrderActions.BULK_DELETE.BEGIN:
    case onlineOrderActions.UPDATE.BEGIN:
    case onlineOrderActions.VIEW.BEGIN:
      return setLoading(state, true);

    case onlineOrderActions.FETCH.SUCCESS:
      return { ...state, onlineOrders: data.data, count: data.total, loading: false, creditScore: data.credit_score };
    case onlineOrderActions.CREATE.SUCCESS:
    case onlineOrderActions.UPDATE.SUCCESS:
    case onlineOrderActions.DELETE.SUCCESS:
      return { ...state, onlineOrder: data.data, loading: false };
    case onlineOrderActions.BULK_DELETE.SUCCESS:
      return { ...state, onlineOrders: data.data, loading: false };
    case onlineOrderActions.VIEW.SUCCESS:
      return { ...state, onlineOrder: data, loading: false };

    case onlineOrderActions.FETCH.ERR:
    case onlineOrderActions.CREATE.ERR:
    case onlineOrderActions.DELETE.ERR:
    case onlineOrderActions.BULK_DELETE.ERR:
    case onlineOrderActions.UPDATE.ERR:
    case onlineOrderActions.VIEW.ERR:
      return setError(setLoading(state, false), err);

    case onlineOrderActions.CLEAR_ERROR:
      return setError(state, null);

    default:
      return state;
  }
};

export default OnlineOrderReducer;

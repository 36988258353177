/* eslint-disable default-param-last */
import actions from './actions';

const {
  LABEL_CREATE_BEGIN,
  LABEL_CREATE_SUCCESS,
  LABEL_CREATE_ERR,

  LABEL_DELETE_BEGIN,
  LABEL_DELETE_SUCCESS,
  LABEL_DELETE_ERR,

  LABEL_UPDATE_BEGIN,
  LABEL_UPDATE_SUCCESS,
  LABEL_UPDATE_ERR,

  LABEL_SHOW_BEGIN,
  LABEL_SHOW_SUCCESS,
  LABEL_SHOW_ERR,

  LABEL_FETCH_BEGIN,
  LABEL_FETCH_SUCCESS,
  LABEL_FETCH_ERR,

  LABEL_CLEAR_ERROR,
} = actions;

const initialState = {
  loading: false,
  error: null,
  labels: [],
  label: {},
  count: 0,
};

const LabelReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LABEL_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LABEL_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        labels: [...state.labels, data],
      };
    case LABEL_CREATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case LABEL_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LABEL_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        labels: state.labels.filter((label) => label.id !== data),
      };
    case LABEL_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case LABEL_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LABEL_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        labels: state.labels.map((label) => {
          if (label.id === data.id) {
            return data;
          }
          return label;
        }),
      };
    case LABEL_UPDATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case LABEL_SHOW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LABEL_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        label: data,
      };
    case LABEL_SHOW_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case LABEL_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LABEL_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        labels: data,
      };
    case LABEL_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case LABEL_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default LabelReducer;

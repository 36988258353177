const actions = {
  RESOURCE_FETCH_BEGIN: 'RESOURCE_FETCH_BEGIN',
  RESOURCE_FETCH_SUCCESS: 'RESOURCE_FETCH_SUCCESS',
  RESOURCE_FETCH_ERR: 'RESOURCE_FETCH_ERR',

  RESOURCE_CREATE_BEGIN: 'RESOURCE_CREATE_BEGIN',
  RESOURCE_CREATE_SUCCESS: 'RESOURCE_CREATE_SUCCESS',
  RESOURCE_CREATE_ERR: 'RESOURCE_CREATE_ERR',

  RESOURCE_DELETE_BEGIN: 'RESOURCE_DELETE_BEGIN',
  RESOURCE_DELETE_SUCCESS: 'RESOURCE_DELETE_SUCCESS',
  RESOURCE_DELETE_ERR: 'RESOURCE_DELETE_ERR',

  RESOURCE_BULK_DELETE_BEGIN: 'RESOURCE_BULK_DELETE_BEGIN',
  RESOURCE_BULK_DELETE_SUCCESS: 'RESOURCE_BULK_DELETE_SUCCESS',
  RESOURCE_BULK_DELETE_ERR: 'RESOURCE_BULK_DELETE_ERR',

  RESOURCE_UPDATE_BEGIN: 'RESOURCE_UPDATE_BEGIN',
  RESOURCE_UPDATE_SUCCESS: 'RESOURCE_UPDATE_SUCCESS',
  RESOURCE_UPDATE_ERR: 'RESOURCE_UPDATE_ERR',

  RESOURCE_VIEW_BEGIN: 'RESOURCE_VIEW_BEGIN',
  RESOURCE_VIEW_SUCCESS: 'RESOURCE_VIEW_SUCCESS',
  RESOURCE_VIEW_ERR: 'RESOURCE_VIEW_ERR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  resourceFetchingBegin: () => ({
    type: actions.RESOURCE_FETCH_BEGIN,
  }),

  resourceFetchingSuccess: (data) => ({
    type: actions.RESOURCE_FETCH_SUCCESS,
    data,
  }),

  resourceFetchingErr: (err) => ({
    type: actions.RESOURCE_FETCH_ERR,
    err,
  }),

  resourceCreatingBegin: () => ({
    type: actions.RESOURCE_CREATE_BEGIN,
  }),

  resourceCreatingSuccess: (data) => ({
    type: actions.RESOURCE_CREATE_SUCCESS,
    data,
  }),

  resourceCreatingErr: (err) => ({
    type: actions.RESOURCE_CREATE_ERR,
    err,
  }),

  resourceDeletingBegin: () => ({
    type: actions.RESOURCE_DELETE_BEGIN,
  }),

  resourceDeletingSuccess: (data) => ({
    type: actions.RESOURCE_DELETE_SUCCESS,
    data,
  }),

  resourceDeletingErr: (err) => ({
    type: actions.RESOURCE_DELETE_ERR,
    err,
  }),

  resourceBulkDeletingBegin: () => ({
    type: actions.RESOURCE_BULK_DELETE_BEGIN,
  }),

  resourceBulkDeletingSuccess: (data) => ({
    type: actions.RESOURCE_BULK_DELETE_SUCCESS,
    data,
  }),

  resourceBulkDeletingErr: (err) => ({
    type: actions.RESOURCE_BULK_DELETE_ERR,
    err,
  }),

  resourceUpdatingBegin: () => ({
    type: actions.RESOURCE_UPDATE_BEGIN,
  }),

  resourceUpdatingSuccess: (data) => ({
    type: actions.RESOURCE_UPDATE_SUCCESS,
    data,
  }),

  resourceUpdatingErr: (err) => ({
    type: actions.RESOURCE_UPDATE_ERR,
    err,
  }),

  resourceViewingBegin: () => ({
    type: actions.RESOURCE_VIEW_BEGIN,
  }),

  resourceViewingSuccess: (data) => ({
    type: actions.RESOURCE_VIEW_SUCCESS,
    data,
  }),

  resourceViewingErr: (err) => ({
    type: actions.RESOURCE_VIEW_ERR,
    err,
  }),

  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
};

export default actions;

import actions from './actions';

const {
  FUNNEL_FETCH_BEGIN,
  FUNNEL_FETCH_SUCCESS,
  FUNNEL_FETCH_ERR,

  FUNNEL_CREATE_BEGIN,
  FUNNEL_CREATE_SUCCESS,
  FUNNEL_CREATE_ERR,

  FUNNEL_DELETE_BEGIN,
  FUNNEL_DELETE_SUCCESS,
  FUNNEL_DELETE_ERR,

  FUNNEL_UPDATE_BEGIN,
  FUNNEL_UPDATE_SUCCESS,
  FUNNEL_UPDATE_ERR,

  FUNNEL_VIEW_BEGIN,
  FUNNEL_VIEW_SUCCESS,
  FUNNEL_VIEW_ERR,

  INCREMENT_BUSINESS_OWNER_COUNT,

  CLEAR_ERROR,
} = actions;

const initialState = {
  funnel: {},
  funnels: [],
  count: 0,
  loading: false,
  error: null,
  premium: false,
  businessOwner: 0,
};

// eslint-disable-next-line default-param-last
const FunnelReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FUNNEL_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FUNNEL_FETCH_SUCCESS:
      return {
        ...state,
        funnels: data.data,
        count: data.total,
        loading: false,
        premium: false,
      };
    case FUNNEL_FETCH_ERR:
      return {
        ...state,
        error: err,
        loading: false,
        premium: err?.status === 'premium',
      };
    case FUNNEL_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FUNNEL_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FUNNEL_CREATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case FUNNEL_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FUNNEL_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        funnel: {},
      };
    case FUNNEL_DELETE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case FUNNEL_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FUNNEL_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FUNNEL_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case FUNNEL_VIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FUNNEL_VIEW_SUCCESS:
      return {
        ...state,
        funnel: data,
        loading: false,
        businessOwner: data.users_count,
      };
    case FUNNEL_VIEW_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case INCREMENT_BUSINESS_OWNER_COUNT:
      return {
        ...state,
        businessOwner: state.businessOwner + 1,
      };
    default:
      return state;
  }
};

export default FunnelReducer;

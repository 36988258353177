/* eslint-disable default-param-last */

import actions from './actions';

const {
  TEAM_SEARCH_BEGIN,
  TEAM_SEARCH_ERR,
  TEAM_SEARCH_SUCCESS,
  TEAM_FETCH_BEGIN,
  TEAM_FETCH_SUCCESS,
  TEAM_FETCH_ERR,
  TEAM_VIEW_BEGIN,
  TEAM_VIEW_SUCCESS,
  TEAM_VIEW_ERR,

  TEAM_TASK_VIEW_BEGIN,
  TEAM_TASK_VIEW_SUCCESS,
  TEAM_TASK_VIEW_ERR,

  TEAM_MESSAGE_VIEW_BEGIN,
  TEAM_MESSAGE_VIEW_SUCCESS,
  TEAM_MESSAGE_VIEW_ERR,

  TEAM_POINT_FETCH_BEGIN,
  TEAM_POINT_FETCH_SUCCESS,
  TEAM_POINT_FETCH_ERR,

  TEAM_POINT_RESET_BEGIN,
  TEAM_POINT_RESET_SUCCESS,
  TEAM_POINT_RESET_ERR,

  TEAM_MEMBER_POINT_RESET_BEGIN,
  TEAM_MEMBER_POINT_RESET_SUCCESS,
  TEAM_MEMBER_POINT_RESET_ERR,
} = actions;

const initState = {
  teams: [],
  members: [],
  searchedTeam: [],
  task: [],
  message: [],
  error: null,
  loading: false,
  taskLoading: false,
  count: 0,
  points: null,
};

const TeamReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TEAM_SEARCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TEAM_SEARCH_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TEAM_SEARCH_SUCCESS:
      return {
        ...state,
        searchedTeam: data,
        loading: false,
      };
    case TEAM_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TEAM_FETCH_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TEAM_FETCH_SUCCESS:
      return {
        ...state,
        teams: data.data,
        loading: false,
        count: data.total,
      };
    case TEAM_VIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TEAM_VIEW_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TEAM_VIEW_SUCCESS:
      return {
        ...state,
        members: data,
        loading: false,
      };
    case TEAM_TASK_VIEW_BEGIN:
      return {
        ...state,
        taskLoading: true,
      };
    case TEAM_TASK_VIEW_ERR:
      return {
        ...state,
        error: err,
        taskLoading: false,
      };
    case TEAM_TASK_VIEW_SUCCESS:
      return {
        ...state,
        task: data.data,
        taskLoading: false,
      };
    case TEAM_MESSAGE_VIEW_BEGIN:
      return {
        ...state,
        taskLoading: true,
      };
    case TEAM_MESSAGE_VIEW_ERR:
      return {
        ...state,
        error: err,
        taskLoading: false,
        message: [],
      };
    case TEAM_MESSAGE_VIEW_SUCCESS:
      return {
        ...state,
        message: data.data,
        taskLoading: false,
      };
    case TEAM_POINT_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TEAM_POINT_FETCH_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TEAM_POINT_FETCH_SUCCESS:
      return {
        ...state,
        points: data,
        loading: false,
      };
    case TEAM_POINT_RESET_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TEAM_POINT_RESET_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TEAM_POINT_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
        points: null,
        teams: state.teams.map((team) => ({ ...team, point: 0 })),
      };
    case TEAM_MEMBER_POINT_RESET_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TEAM_MEMBER_POINT_RESET_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TEAM_MEMBER_POINT_RESET_SUCCESS:
      console.log(state.points);
      return {
        ...state,
        loading: false,
        teams: state.teams.map((member) => {
          if (member.id === data.user) {
            return { ...member, point: 0 };
          }
          return member;
        }),
        points: {
          ...state.points,
          org_point: state.points.org_point > 0 ? state.points.org_point - data.point : state.points.org_point,
          team_point: state.points.team_point > 0 ? state.points.team_point - data.point : state.points.team_point,
        },
      };

    default:
      return state;
  }
};

export default TeamReducer;

const actions = {
  FUNNEL_FETCH_BEGIN: 'FUNNEL_FETCH_BEGIN',
  FUNNEL_FETCH_SUCCESS: 'FUNNEL_FETCH_SUCCESS',
  FUNNEL_FETCH_ERR: 'FUNNEL_FETCH_ERR',

  FUNNEL_CREATE_BEGIN: 'FUNNEL_CREATE_BEGIN',
  FUNNEL_CREATE_SUCCESS: 'FUNNEL_CREATE_SUCCESS',
  FUNNEL_CREATE_ERR: 'FUNNEL_CREATE_ERR',

  FUNNEL_DELETE_BEGIN: 'FUNNEL_DELETE_BEGIN',
  FUNNEL_DELETE_SUCCESS: 'FUNNEL_DELETE_SUCCESS',
  FUNNEL_DELETE_ERR: 'FUNNEL_DELETE_ERR',

  FUNNEL_UPDATE_BEGIN: 'FUNNEL_UPDATE_BEGIN',
  FUNNEL_UPDATE_SUCCESS: 'FUNNEL_UPDATE_SUCCESS',
  FUNNEL_UPDATE_ERR: 'FUNNEL_UPDATE_ERR',

  FUNNEL_VIEW_BEGIN: 'FUNNEL_VIEW_BEGIN',
  FUNNEL_VIEW_SUCCESS: 'FUNNEL_VIEW_SUCCESS',
  FUNNEL_VIEW_ERR: 'FUNNEL_VIEW_ERR',

  INCREMENT_BUSINESS_OWNER_COUNT: 'INCREMENT_BUSINESS_OWNER_COUNT',

  CLEAR_ERROR: 'CLEAR_ERROR',

  funnelFetchBegin: () => ({
    type: actions.FUNNEL_FETCH_BEGIN,
  }),

  funnelFetchSuccess: (data) => ({
    type: actions.FUNNEL_FETCH_SUCCESS,
    data,
  }),

  funnelFetchErr: (err) => ({
    type: actions.FUNNEL_FETCH_ERR,
    err,
  }),

  funnelCreateBegin: () => ({
    type: actions.FUNNEL_CREATE_BEGIN,
  }),

  funnelCreateSuccess: (data) => ({
    type: actions.FUNNEL_CREATE_SUCCESS,
    data,
  }),

  funnelCreateErr: (err) => ({
    type: actions.FUNNEL_CREATE_ERR,
    err,
  }),

  funnelDeleteBegin: () => ({
    type: actions.FUNNEL_DELETE_BEGIN,
  }),

  funnelDeleteSuccess: (data) => ({
    type: actions.FUNNEL_DELETE_SUCCESS,
    data,
  }),

  funnelDeleteErr: (err) => ({
    type: actions.FUNNEL_DELETE_ERR,
    err,
  }),

  funnelUpdateBegin: () => ({
    type: actions.FUNNEL_UPDATE_BEGIN,
  }),

  funnelUpdateSuccess: (data) => ({
    type: actions.FUNNEL_UPDATE_SUCCESS,
    data,
  }),

  funnelUpdateErr: (err) => ({
    type: actions.FUNNEL_UPDATE_ERR,
    err,
  }),

  funnelViewBegin: () => ({
    type: actions.FUNNEL_VIEW_BEGIN,
  }),

  funnelViewSuccess: (data) => ({
    type: actions.FUNNEL_VIEW_SUCCESS,
    data,
  }),

  funnelViewErr: (err) => ({
    type: actions.FUNNEL_VIEW_ERR,
    err,
  }),

  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),

  incrementBusinessOwnerCount: () => ({
    type: actions.INCREMENT_BUSINESS_OWNER_COUNT,
  }),
};

export default actions;

import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../../../../components/heading/heading';

const FooItem = ({ title, description, icon }) => (
  <div className="foo-d-wrap ">
    <div className="foo-icon">
      <img src={icon} alt="Pamtree" height="85" />
    </div>
    <div>
      <Heading as="h3">{title}</Heading>
      <p>{description}</p>
    </div>
  </div>
);

export default FooItem;

FooItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.string,
};

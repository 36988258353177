import actions from './actions';

const {
  RESOURCE_FETCH_BEGIN,
  RESOURCE_FETCH_SUCCESS,
  RESOURCE_FETCH_ERR,

  RESOURCE_CREATE_BEGIN,
  RESOURCE_CREATE_SUCCESS,
  RESOURCE_CREATE_ERR,

  RESOURCE_DELETE_BEGIN,
  RESOURCE_DELETE_SUCCESS,
  RESOURCE_DELETE_ERR,

  RESOURCE_BULK_DELETE_BEGIN,
  RESOURCE_BULK_DELETE_SUCCESS,
  RESOURCE_BULK_DELETE_ERR,

  RESOURCE_UPDATE_BEGIN,
  RESOURCE_UPDATE_SUCCESS,
  RESOURCE_UPDATE_ERR,

  RESOURCE_VIEW_BEGIN,
  RESOURCE_VIEW_SUCCESS,
  RESOURCE_VIEW_ERR,

  CLEAR_ERROR,
} = actions;

const initialState = {
  loading: false,
  error: null,
  resources: [],
  resource: null,
  count: 0,
};

// eslint-disable-next-line default-param-last
const ResourceReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case RESOURCE_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RESOURCE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        resources: data.data,
        count: data.total,
      };
    case RESOURCE_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case RESOURCE_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESOURCE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        resources: [...state.resources, data],
        count: state.count + 1,
      };
    case RESOURCE_CREATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case RESOURCE_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESOURCE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        resources: state.resources.filter((resource) => resource.id !== data),
        count: state.count - 1,
      };
    case RESOURCE_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case RESOURCE_BULK_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESOURCE_BULK_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        resources: state.resources.filter((resource) => !data.includes(resource.id)),
        count: state.count - data.length,
      };
    case RESOURCE_BULK_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case RESOURCE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESOURCE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        resources: state.resources.map((resource) => (resource.id === data.id ? data : resource)),
      };
    case RESOURCE_UPDATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case RESOURCE_VIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case RESOURCE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        resource: data,
      };
    case RESOURCE_VIEW_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default ResourceReducer;

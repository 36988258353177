const actions = {
  ORDER_FETCH_BEGIN: 'ORDER_FETCH_BEGIN',
  ORDER_FETCH_SUCCESS: 'ORDER_FETCH_SUCCESS',
  ORDER_FETCH_ERR: 'ORDER_FETCH_ERR',

  ORDER_CREATE_BEGIN: 'ORDER_CREATE_BEGIN',
  ORDER_CREATE_SUCCESS: 'ORDER_CREATE_SUCCESS',
  ORDER_CREATE_ERR: 'ORDER_CREATE_ERR',

  ORDER_DELETE_BEGIN: 'ORDER_DELETE_BEGIN',
  ORDER_DELETE_SUCCESS: 'ORDER_DELETE_SUCCESS',
  ORDER_DELETE_ERR: 'ORDER_DELETE_ERR',

  ORDER_BULK_DELETE_BEGIN: 'ORDER_BULK_DELETE_BEGIN',
  ORDER_BULK_DELETE_SUCCESS: 'ORDER_BULK_DELETE_SUCCESS',
  ORDER_BULK_DELETE_ERR: 'ORDER_BULK_DELETE_ERR',

  ORDER_UPDATE_BEGIN: 'ORDER_UPDATE_BEGIN',
  ORDER_UPDATE_SUCCESS: 'ORDER_UPDATE_SUCCESS',
  ORDER_UPDATE_ERR: 'ORDER_UPDATE_ERR',

  ORDER_VIEW_BEGIN: 'ORDER_VIEW_BEGIN',
  ORDER_VIEW_SUCCESS: 'ORDER_VIEW_SUCCESS',
  ORDER_VIEW_ERR: 'ORDER_VIEW_ERR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  orderFetchBegin: () => ({
    type: actions.ORDER_FETCH_BEGIN,
  }),

  orderFetchSuccess: (data) => ({
    type: actions.ORDER_FETCH_SUCCESS,
    data,
  }),

  orderFetchErr: (err) => ({
    type: actions.ORDER_FETCH_ERR,
    err,
  }),

  orderCreateBegin: () => ({
    type: actions.ORDER_CREATE_BEGIN,
  }),

  orderCreateSuccess: (data) => ({
    type: actions.ORDER_CREATE_SUCCESS,
    data,
  }),

  orderCreateErr: (err) => ({
    type: actions.ORDER_CREATE_ERR,
    err,
  }),

  orderDeleteBegin: () => ({
    type: actions.ORDER_DELETE_BEGIN,
  }),

  orderDeleteSuccess: (data) => ({
    type: actions.ORDER_DELETE_SUCCESS,
    data,
  }),

  orderDeleteErr: (err) => ({
    type: actions.ORDER_DELETE_ERR,
    err,
  }),

  orderBulkDeleteBegin: () => ({
    type: actions.ORDER_BULK_DELETE_BEGIN,
  }),

  orderBulkDeleteSuccess: (data) => ({
    type: actions.ORDER_BULK_DELETE_SUCCESS,
    data,
  }),

  orderBulkDeleteErr: (err) => ({
    type: actions.ORDER_BULK_DELETE_ERR,
    err,
  }),

  orderUpdateBegin: () => ({
    type: actions.ORDER_UPDATE_BEGIN,
  }),

  orderUpdateSuccess: (data) => ({
    type: actions.ORDER_UPDATE_SUCCESS,
    data,
  }),

  orderUpdateErr: (err) => ({
    type: actions.ORDER_UPDATE_ERR,
    err,
  }),

  orderViewBegin: () => ({
    type: actions.ORDER_VIEW_BEGIN,
  }),

  orderViewSuccess: (data) => ({
    type: actions.ORDER_VIEW_SUCCESS,
    data,
  }),

  orderViewErr: (err) => ({
    type: actions.ORDER_VIEW_ERR,
    err,
  }),

  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
};

export default actions;

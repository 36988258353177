import actions from './actions';

const {
  FETCH_NOTIFICATIONS_BEGIN,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERR,

  FETCH_RECENT_NOTIFICATIONS_BEGIN,
  FETCH_RECENT_NOTIFICATIONS_SUCCESS,
  FETCH_RECENT_NOTIFICATIONS_ERR,

  DELETE_NOTIFICATION_BEGIN,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_ERR,

  DELETE_ALL_NOTIFICATIONS_BEGIN,
  DELETE_ALL_NOTIFICATIONS_SUCCESS,
  DELETE_ALL_NOTIFICATIONS_ERR,

  MARK_AS_READ_BEGIN,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_ERR,

  MARK_ALL_AS_READ_BEGIN,
  MARK_ALL_AS_READ_SUCCESS,
  MARK_ALL_AS_READ_ERR,

  CLEAR_ERROR,
} = actions;

const initialState = {
  notifications: [],
  recentNotifications: [],
  error: null,
  count: 0,
  loading: false,
  loadingRecent: false,
};

// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
  const { type, data, err } = action;
  switch (type) {
    case FETCH_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: data,
        loading: false,
      };
    case FETCH_NOTIFICATIONS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case FETCH_RECENT_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        loadingRecent: true,
      };
    case FETCH_RECENT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        recentNotifications: data.data,
        count: data.count,
        loadingRecent: false,
      };
    case FETCH_RECENT_NOTIFICATIONS_ERR:
      return {
        ...state,
        error: err,
        loadingRecent: false,
      };
    case DELETE_NOTIFICATION_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.id !== data),
        loading: false,
      };
    case DELETE_NOTIFICATION_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case DELETE_ALL_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: [],
        loading: false,
      };
    case DELETE_ALL_NOTIFICATIONS_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case MARK_AS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MARK_AS_READ_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.id === data.id ? { ...notification, read_at: true } : notification,
        ),
        loading: false,
        count: state.count > 0 ? state.count - 1 : 0,
        recentNotifications: state.recentNotifications.filter((notification) => notification.id !== data.id),
      };
    case MARK_AS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case MARK_ALL_AS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case MARK_ALL_AS_READ_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map((notification) => ({
          ...notification,
          read_at: true,
        })),
        recentNotifications: [],
        count: 0,
        loading: false,
      };
    case MARK_ALL_AS_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

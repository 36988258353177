import actions from './actions';

const {
  CONTACT_CREATING_FROM_SELECT_SUCCESS,
  CONTACT_CREATING_FROM_SELECT_ERR,
  CONTACT_CREATING_FROM_SELECT_BEGIN,
  CONTACT_CREATING_FROM_SELECT_CLOSE_MODEL,
  CLEAR_SELECT_STATE,

  CONTACT_FETCH_BEGIN,
  CONTACT_FETCH_SUCCESS,
  CONTACT_FETCH_ERR,

  CONTACT_CREATE_BEGIN,
  CONTACT_CREATE_SUCCESS,
  CONTACT_CREATE_ERR,

  CONTACT_UPDATE_BEGIN,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_ERR,

  CONTACT_DELETE_BEGIN,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DELETE_ERR,

  CONTACT_BULK_DELETE_BEGIN,
  CONTACT_BULK_DELETE_SUCCESS,
  CONTACT_BULK_DELETE_ERR,

  STAGE_FETCH_BEGIN,
  STAGE_FETCH_SUCCESS,
  STAGE_FETCH_ERR,

  CONTACT_VIEW_BEGIN,
  CONTACT_VIEW_SUCCESS,
  CONTACT_VIEW_ERR,

  CONTACT_RECENT_FETCH_BEGIN,
  CONTACT_RECENT_FETCH_SUCCESS,
  CONTACT_RECENT_FETCH_ERR,

  GET_DUPLICATE_CONTACTS_BEGIN,
  GET_DUPLICATE_CONTACTS_SUCCESS,
  GET_DUPLICATE_CONTACTS_ERR,

  CONTACT_BULK_ADD_TO_MAILCHIMP_BEGIN,
  CONTACT_BULK_ADD_TO_MAILCHIMP_SUCCESS,
  CONTACT_BULK_ADD_TO_MAILCHIMP_ERR,

  STAGE_CREATE_BEGIN,
  STAGE_CREATE_SUCCESS,
  STAGE_CREATE_ERR,

  STAGE_DELETE_SUCCESS,
  STAGE_DELETE_BEGIN,
  STAGE_DELETE_ERR,

  CLEAR_ERROR,
} = actions;

const initState = {
  contacts: [],
  recentContacts: [],
  contact: {},
  stages: [],
  duplicateContacts: {},
  count: 0,
  error: null,
  loading: false,
  isAddingContact: false,
  createdFromSelectContact: [],
  selectCreatingContactName: '',
  status: '',
  statusType: '',
  bulkMessage: '',
};

// eslint-disable-next-line default-param-last
const ContactReducer = (state = initState, action) => {
  const { type, data, err, ids } = action;
  switch (type) {
    case CONTACT_CREATING_FROM_SELECT_BEGIN:
      return {
        ...state,
        isAddingContact: true,
        selectCreatingContactName: data,
      };
    case CONTACT_CREATING_FROM_SELECT_SUCCESS:
      return {
        ...state,
        createdFromSelectContact: data,
        isAddingContact: false,
      };
    case CONTACT_CREATING_FROM_SELECT_ERR:
      return {
        ...state,
        error: err,
        isAddingContact: false,
      };
    case CONTACT_CREATING_FROM_SELECT_CLOSE_MODEL:
      return {
        ...state,
        selectCreatingContactName: '',
        isAddingContact: false,
      };
    case CLEAR_SELECT_STATE:
      return {
        ...state,
        createdFromSelectContact: [],
        selectCreatingContactName: '',
      };
    case CONTACT_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_FETCH_SUCCESS:
      return {
        ...state,
        contacts: data.data,
        count: data.total,
        loading: false,
      };
    case CONTACT_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CONTACT_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: [data, ...state.contacts],
      };
    case CONTACT_CREATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CONTACT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: state.contacts.map((contact) => (contact.id === data.id ? data : contact)),
      };
    case CONTACT_UPDATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CONTACT_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: state.contacts.filter((contact) => contact.id !== data.id),
      };
    case CONTACT_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CONTACT_BULK_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_BULK_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: state.contacts.filter((contact) => !ids.includes(contact.id)),
        bulkMessage: data,
      };
    case CONTACT_BULK_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CONTACT_BULK_ADD_TO_MAILCHIMP_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_BULK_ADD_TO_MAILCHIMP_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'Contacts added to mailchimp',
        statusType: 'success',
      };
    case CONTACT_BULK_ADD_TO_MAILCHIMP_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CONTACT_VIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        contact: data,
      };
    case CONTACT_VIEW_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case STAGE_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case STAGE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        stages: data,
      };
    case STAGE_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        status: '',
        statusType: '',
      };
    case STAGE_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case STAGE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        stages: [...state.stages, data],
      };
    case STAGE_CREATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CONTACT_RECENT_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case CONTACT_RECENT_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        recentContacts: data.data,
      };
    case CONTACT_RECENT_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case GET_DUPLICATE_CONTACTS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_DUPLICATE_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        duplicateContacts: data,
      };
    case GET_DUPLICATE_CONTACTS_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case STAGE_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case STAGE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        stages: state.stages.filter((stage) => stage.id !== data),
      };
    case STAGE_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    default:
      return state;
  }
};

export default ContactReducer;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const HeaderMenu = ({ companyLogo }) => (
  <Link to="/admin" className="logosite">
    {companyLogo ? (
      <img className="site-logo" src={companyLogo} alt="" />
    ) : (
      <img className="site-logo" src={require(`../static/img/logo.svg`).default} alt="" />
    )}
  </Link>
);

export default HeaderMenu;

HeaderMenu.propTypes = {
  companyLogo: PropTypes.string,
};

import { combineReducers } from 'redux';
import authReducer from './authentication/reducers';
import headerSearchReducer from './headerSearch/reducers';
import tagReducer from './tags/reducer';
import CategoryReducer from './category/reducer';
import ProductReducer from './products/reducer';
import ResourceReducer from './resources/reducer';
import ContactReducer from './contacts/reducer';
import TeamReducer from './myTeam/reducer';
import IntegrationReducer from './integration/reducer';
import mailChimpReducer from './mailchimp/reducer';
import TodoReducer from './todo/reducer';
import NotificationReducer from './notifications/reducer';
import MessageReducer from './messages/reducer';
import MessageTemplateReducer from './messageTemplate/reducer';
import OrderReducer from './customerTracker/reducers';
import OnlineOrderReducer from './customerTracker/OnlineOrderReducer';
import FunnelTemplateReducer from './funnelTemplate/reducers';
import FunnelReducer from './funnel/reducers';
import FunnelStepReducer from './funnelStep/reducers';
import NoteReducer from './notes/reducer';
import LabelReducer from './label/reducer';
import ProfileReducer from './profile/reducer';
import chartContentReducer from './chartContent/reducers';

const rootReducers = combineReducers({
  auth: authReducer,
  headerSearchData: headerSearchReducer,
  tags: tagReducer,
  categories: CategoryReducer,
  products: ProductReducer,
  resources: ResourceReducer,
  contacts: ContactReducer,
  todo: TodoReducer,
  myTeam: TeamReducer,
  integration: IntegrationReducer,
  mailChimp: mailChimpReducer,
  notifications: NotificationReducer,
  message: MessageReducer,
  messageTemplates: MessageTemplateReducer,
  orders: OrderReducer,
  onlineOrders: OnlineOrderReducer,
  funnelTemplate: FunnelTemplateReducer,
  funnel: FunnelReducer,
  steps: FunnelStepReducer,
  notes: NoteReducer,
  labels: LabelReducer,
  profile: ProfileReducer,
  chartContent: chartContentReducer,
});

export default rootReducers;

import actions from './actions';
import { DataService } from '../../config/dataService';

const {
  fetchNotificationsBegin,
  fetchNotificationsSuccess,
  fetchNotificationsErr,

  fetchRecentNotificationsBegin,
  fetchRecentNotificationsSuccess,
  fetchRecentNotificationsErr,

  deleteNotificationBegin,
  deleteNotificationSuccess,
  deleteNotificationErr,

  deleteAllNotificationsBegin,
  deleteAllNotificationsSuccess,
  deleteAllNotificationsErr,

  markAsReadBegin,
  markAsReadSuccess,
  markAsReadErr,

  markAllAsReadBegin,
  markAllAsReadSuccess,
  markAllAsReadErr,

  clearError,
} = actions;

export const fetchNotifications = () => async (dispatch) => {
  dispatch(fetchNotificationsBegin());
  try {
    const data = await DataService.get('/notifications');

    if (data.data.errors) {
      throw new Error(data.data.errors);
    }
    dispatch(fetchNotificationsSuccess(data.data.data));
  } catch (err) {
    dispatch(fetchNotificationsErr(err.response.data));
  }
};

export const fetchRecentNotifications = () => async (dispatch) => {
  dispatch(fetchRecentNotificationsBegin());
  try {
    const data = await DataService.get('/notifications/recent');

    if (data.data.errors) {
      throw new Error(data.data.errors);
    }
    dispatch(fetchRecentNotificationsSuccess(data.data));
  } catch (err) {
    dispatch(fetchRecentNotificationsErr(err.response.data));
  }
};

export const deleteNotification = (id) => async (dispatch) => {
  dispatch(deleteNotificationBegin());
  try {
    const data = await DataService.delete(`/notifications/${id}`);

    if (data.data.errors) {
      throw new Error(data.data.errors);
    }

    dispatch(deleteNotificationSuccess(id));
  } catch (err) {
    dispatch(deleteNotificationErr(err.response.data));
  }
};

export const deleteAllNotifications = () => async (dispatch) => {
  dispatch(deleteAllNotificationsBegin());
  try {
    const data = await DataService.post('/notifications/delete-all');

    if (data.data.errors) {
      throw new Error(data.data.errors);
    }

    dispatch(deleteAllNotificationsSuccess(data.data.data));
  } catch (err) {
    dispatch(deleteAllNotificationsErr(err.response.data));
  }
};

export const markAsRead = (id) => async (dispatch) => {
  dispatch(markAsReadBegin());
  try {
    const data = await DataService.post('/notifications/mark-as-read', { id });

    if (data.data.errors) {
      throw new Error(data.data.errors);
    }
    dispatch(markAsReadSuccess(data.data.data));
  } catch (err) {
    dispatch(markAsReadErr(err.response.data));
  }
};

export const markAllAsRead = () => async (dispatch) => {
  dispatch(markAllAsReadBegin());
  try {
    const data = await DataService.post('/notifications/mark-all-as-read');

    if (data.data.errors) {
      throw new Error(data.data.errors);
    }
    dispatch(markAllAsReadSuccess(data.data.data));

    // setTimeout(() => {
    //   dispatch(fetchNotifications());
    //   dispatch(fetchRecentNotifications());
    // }, 500);
  } catch (err) {
    dispatch(markAllAsReadErr(err.response.data));
  }
};

export const clearErrorAction = () => (dispatch) => {
  dispatch(clearError());
};

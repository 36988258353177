/* eslint-disable default-param-last */
import actions from './actions';

const {
  TODO_FETCH_BEGIN,
  TODO_FETCH_SUCCESS,
  TODO_FETCH_ERR,

  TODO_CREATE_BEGIN,
  TODO_CREATE_SUCCESS,
  TODO_CREATE_ERR,

  TODO_DELETE_BEGIN,
  TODO_DELETE_SUCCESS,
  TODO_DELETE_ERR,

  TODO_UPDATE_BEGIN,
  TODO_UPDATE_SUCCESS,
  TODO_UPDATE_ERR,

  TODO_BULK_DELETE_BEGIN,
  TODO_BULK_DELETE_SUCCESS,
  TODO_BULK_DELETE_ERR,

  TODO_BULK_COMPLETE_BEGIN,
  TODO_BULK_COMPLETE_SUCCESS,
  TODO_BULK_COMPLETE_ERR,

  TODO_BULK_INCOMPLETE_BEGIN,
  TODO_BULK_INCOMPLETE_SUCCESS,
  TODO_BULK_INCOMPLETE_ERR,

  TODO_VIEW_BEGIN,
  TODO_VIEW_SUCCESS,
  TODO_VIEW_ERR,

  TODO_COMPLETE_BEGIN,
  TODO_COMPLETE_SUCCESS,
  TODO_COMPLETE_ERR,

  TODO_PIN_BEGIN,
  TODO_PIN_SUCCESS,
  TODO_PIN_ERR,

  CLEAR_ERROR,
} = actions;

const initialState = {
  todos: [],
  todo: {},
  error: null,
  loading: false,
  count: 0,
};

const todoReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TODO_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TODO_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        todos: data.data,
        count: data.total,
      };
    case TODO_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case TODO_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TODO_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        todos: [...state.todos, data],
        count: state.count + 1,
      };
    case TODO_CREATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case TODO_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TODO_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        todos: state.todos.filter((todo) => todo.id !== data.id),
        count: state.count - 1,
      };
    case TODO_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case TODO_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TODO_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        todos: state.todos.map((todo) => (todo.id === data.id ? data : todo)),
      };
    case TODO_UPDATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case TODO_BULK_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TODO_BULK_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        todos: state.todos.filter((todo) => !data.includes(todo.id)),
      };
    case TODO_BULK_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case TODO_BULK_COMPLETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TODO_BULK_COMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        todos: state.todos.map((todo) => (data.includes(todo.id) ? { ...todo, completed: true } : todo)),
      };
    case TODO_BULK_COMPLETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case TODO_BULK_INCOMPLETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TODO_BULK_INCOMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TODO_BULK_INCOMPLETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case TODO_VIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TODO_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        todo: data,
      };
    case TODO_VIEW_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case TODO_COMPLETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TODO_COMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        todos: state.todos.map((todo) => (todo.id === data.id ? { ...todo, completed: true } : todo)),
      };
    case TODO_COMPLETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case TODO_PIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TODO_PIN_SUCCESS:
      return {
        ...state,
        loading: false,
        todos: state.todos.map((todo) => (todo.id === data.id ? { ...todo, pinned: true } : todo)),
      };
    case TODO_PIN_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    default:
      return state;
  }
};
export default todoReducer;

import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const EmailVerification = lazy(() => import('../container/profile/authentication/EmailVerification'));
const VerificationPage = lazy(() => import('../container/profile/authentication/VerificationPage'));
// const NotFound = lazy(() => import('../container/404/NotFound'));

function VerificationRoutes() {
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/verify" component={EmailVerification} />
        <Route exact path="/verifying" component={VerificationPage} />

        {/* <Route exact path="*" component={NotFound} /> */}
      </Suspense>
    </Switch>
  );
}

export default AuthLayout(VerificationRoutes);

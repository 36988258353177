const actions = {
  CATEGORY_CREATING_FROM_SELECT_BEGIN: 'CATEGORY_CREATING_FROM_SELECT_BEGIN',
  CATEGORY_CREATING_FROM_SELECT_SUCCESS: 'CATEGORY_CREATING_FROM_SELECT_SUCCESS',
  CATEGORY_CREATING_FROM_SELECT_ERR: 'CATEGORY_CREATING_FROM_SELECT_ERR',
  CATEGORY_CREATING_FROM_SELECT_CLOSE_MODEL: 'CATEGORY_CREATING_FROM_SELECT_CLOSE_MODEL',
  CLEAR_SELECT_STATE: 'CLEAR_SELECT_STATE',

  CATEGORY_FETCH_BEGIN: 'CATEGORY_FETCH_BEGIN',
  CATEGORY_FETCH_SUCCESS: 'CATEGORY_FETCH_SUCCESS',
  CATEGORY_FETCH_ERR: 'CATEGORY_FETCH_ERR',

  CATEGORY_CREATE_BEGIN: 'CATEGORY_CREATE_BEGIN',
  CATEGORY_CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
  CATEGORY_CREATE_ERR: 'CATEGORY_CREATE_ERR',

  CATEGORY_DELETE_BEGIN: 'CATEGORY_DELETE_BEGIN',
  CATEGORY_DELETE_SUCCESS: 'CATEGORY_DELETE_SUCCESS',
  CATEGORY_DELETE_ERR: 'CATEGORY_DELETE_ERR',

  CATEGORY_BULK_DELETE_BEGIN: 'CATEGORY_BULK_DELETE_BEGIN',
  CATEGORY_BULK_DELETE_SUCCESS: 'CATEGORY_BULK_DELETE_SUCCESS',
  CATEGORY_BULK_DELETE_ERR: 'CATEGORY_BULK_DELETE_ERR',

  CATEGORY_SEARCH_BEGIN: 'CATEGORY_SEARCH_BEGIN',
  CATEGORY_SEARCH_SUCCESS: 'CATEGORY_SEARCH_SUCCESS',
  CATEGORY_SEARCH_ERR: 'CATEGORY_SEARCH_ERR',

  CATEGORY_UPDATE_BEGIN: 'CATEGORY_UPDATE_BEGIN',
  CATEGORY_UPDATE_SUCCESS: 'CATEGORY_UPDATE_SUCCESS',
  CATEGORY_UPDATE_ERR: 'CATEGORY_UPDATE_ERR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  categoryCreatingFromSelectBegin: (data) => ({
    type: actions.CATEGORY_CREATING_FROM_SELECT_BEGIN,
    data,
  }),

  categoryCreatingFromSelectSuccess: (data) => ({
    type: actions.CATEGORY_CREATING_FROM_SELECT_SUCCESS,
    data,
  }),

  clearSelectState: () => ({
    type: actions.CLEAR_SELECT_STATE,
  }),

  categoryCreatingFromSelectErr: (err) => ({
    type: actions.CATEGORY_CREATING_FROM_SELECT_ERR,
    err,
  }),

  categoryFetchBegin: () => ({
    type: actions.CATEGORY_FETCH_BEGIN,
  }),

  categoryFetchSuccess: (data) => ({
    type: actions.CATEGORY_FETCH_SUCCESS,
    data,
  }),

  categoryFetchErr: (err) => ({
    type: actions.CATEGORY_FETCH_ERR,
    err,
  }),

  categoryCreateBegin: () => ({
    type: actions.CATEGORY_CREATE_BEGIN,
  }),

  categoryCreateSuccess: (data) => ({
    type: actions.CATEGORY_CREATE_SUCCESS,
    data,
  }),

  categoryCreateErr: (err) => ({
    type: actions.CATEGORY_CREATE_ERR,
    err,
  }),

  categoryDeleteBegin: () => ({
    type: actions.CATEGORY_DELETE_BEGIN,
  }),

  categoryDeleteSuccess: (data) => ({
    type: actions.CATEGORY_DELETE_SUCCESS,
    data,
  }),

  categoryDeleteErr: (err) => ({
    type: actions.CATEGORY_DELETE_ERR,
    err,
  }),

  categoryBulkDeleteBegin: () => ({
    type: actions.CATEGORY_BULK_DELETE_BEGIN,
  }),

  categoryBulkDeleteSuccess: (ids, data) => ({
    type: actions.CATEGORY_BULK_DELETE_SUCCESS,
    ids,
    data,
  }),

  categoryBulkDeleteErr: (err) => ({
    type: actions.CATEGORY_BULK_DELETE_ERR,
    err,
  }),

  categorySearchBegin: () => ({
    type: actions.CATEGORY_SEARCH_BEGIN,
  }),

  categorySearchSuccess: (data) => ({
    type: actions.CATEGORY_SEARCH_SUCCESS,
    data,
  }),

  categorySearchErr: (err) => ({
    type: actions.CATEGORY_SEARCH_ERR,
    err,
  }),

  categoryUpdateBegin: () => ({
    type: actions.CATEGORY_UPDATE_BEGIN,
  }),

  categoryUpdateSuccess: (data) => ({
    type: actions.CATEGORY_UPDATE_SUCCESS,
    data,
  }),

  categoryUpdateErr: (err) => ({
    type: actions.CATEGORY_UPDATE_ERR,
    err,
  }),

  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
};

export default actions;

import actions from './actions';

const {
  TEMPLATE_FETCH_BEGIN,
  TEMPLATE_FETCH_SUCCESS,
  TEMPLATE_FETCH_ERR,

  TEMPLATE_CREATE_BEGIN,
  TEMPLATE_CREATE_SUCCESS,
  TEMPLATE_CREATE_ERR,

  TEMPLATE_DELETE_BEGIN,
  TEMPLATE_DELETE_SUCCESS,
  TEMPLATE_DELETE_ERR,

  TEMPLATE_UPDATE_BEGIN,
  TEMPLATE_UPDATE_SUCCESS,
  TEMPLATE_UPDATE_ERR,

  TEMPLATE_VIEW_BEGIN,
  TEMPLATE_VIEW_SUCCESS,
  TEMPLATE_VIEW_ERR,

  CLEAR_ERROR,
} = actions;

const initialState = {
  loading: false,
  error: null,
  templates: [],
  template: {},
  count: 0,
};

// eslint-disable-next-line default-param-last
const templateReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case TEMPLATE_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TEMPLATE_FETCH_SUCCESS:
      return {
        ...state,
        templates: data.data,
        count: data.total,
        loading: false,
      };
    case TEMPLATE_FETCH_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TEMPLATE_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        template: data.data,
        loading: false,
      };
    case TEMPLATE_CREATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TEMPLATE_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TEMPLATE_DELETE_SUCCESS:
      return {
        ...state,
        template: data.data,
        loading: false,
      };
    case TEMPLATE_DELETE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TEMPLATE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TEMPLATE_UPDATE_SUCCESS:
      return {
        ...state,
        template: data.data,
        loading: false,
      };
    case TEMPLATE_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case TEMPLATE_VIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case TEMPLATE_VIEW_SUCCESS:
      return {
        ...state,
        template: data,
        loading: false,
      };
    case TEMPLATE_VIEW_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default templateReducer;

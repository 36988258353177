const actions = {
  FETCH_NOTIFICATIONS_BEGIN: 'FETCH_NOTIFICATIONS_BEGIN',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_ERR: 'FETCH_NOTIFICATIONS_ERR',

  // recent notifications
  FETCH_RECENT_NOTIFICATIONS_BEGIN: 'FETCH_RECENT_NOTIFICATIONS_BEGIN',
  FETCH_RECENT_NOTIFICATIONS_SUCCESS: 'FETCH_RECENT_NOTIFICATIONS_SUCCESS',
  FETCH_RECENT_NOTIFICATIONS_ERR: 'FETCH_RECENT_NOTIFICATIONS_ERR',

  // delete
  DELETE_NOTIFICATION_BEGIN: 'DELETE_NOTIFICATION_BEGIN',
  DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION_ERR: 'DELETE_NOTIFICATION_ERR',

  // delete all
  DELETE_ALL_NOTIFICATIONS_BEGIN: 'DELETE_ALL_NOTIFICATIONS_BEGIN',
  DELETE_ALL_NOTIFICATIONS_SUCCESS: 'DELETE_ALL_NOTIFICATIONS_SUCCESS',
  DELETE_ALL_NOTIFICATIONS_ERR: 'DELETE_ALL_NOTIFICATIONS_ERR',

  // mark as read
  MARK_AS_READ_BEGIN: 'MARK_AS_READ_BEGIN',
  MARK_AS_READ_SUCCESS: 'MARK_AS_READ_SUCCESS',
  MARK_AS_READ_ERR: 'MARK_AS_READ_ERR',

  // mark all as read
  MARK_ALL_AS_READ_BEGIN: 'MARK_ALL_AS_READ_BEGIN',
  MARK_ALL_AS_READ_SUCCESS: 'MARK_ALL_AS_READ_SUCCESS',
  MARK_ALL_AS_READ_ERR: 'MARK_ALL_AS_READ_ERR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  fetchNotificationsBegin: () => ({
    type: actions.FETCH_NOTIFICATIONS_BEGIN,
  }),

  fetchNotificationsSuccess: (data) => ({
    type: actions.FETCH_NOTIFICATIONS_SUCCESS,
    data,
  }),

  fetchNotificationsErr: (err) => ({
    type: actions.FETCH_NOTIFICATIONS_ERR,
    err,
  }),

  fetchRecentNotificationsBegin: () => ({
    type: actions.FETCH_RECENT_NOTIFICATIONS_BEGIN,
  }),

  fetchRecentNotificationsSuccess: (data) => ({
    type: actions.FETCH_RECENT_NOTIFICATIONS_SUCCESS,
    data,
  }),

  fetchRecentNotificationsErr: (err) => ({
    type: actions.FETCH_RECENT_NOTIFICATIONS_ERR,
    err,
  }),

  deleteNotificationBegin: () => ({
    type: actions.DELETE_NOTIFICATION_BEGIN,
  }),

  deleteNotificationSuccess: (data) => ({
    type: actions.DELETE_NOTIFICATION_SUCCESS,
    data,
  }),

  deleteNotificationErr: (err) => ({
    type: actions.DELETE_NOTIFICATION_ERR,
    err,
  }),

  deleteAllNotificationsBegin: () => ({
    type: actions.DELETE_ALL_NOTIFICATIONS_BEGIN,
  }),

  deleteAllNotificationsSuccess: (data) => ({
    type: actions.DELETE_ALL_NOTIFICATIONS_SUCCESS,
    data,
  }),

  deleteAllNotificationsErr: (err) => ({
    type: actions.DELETE_ALL_NOTIFICATIONS_ERR,
    err,
  }),

  markAsReadBegin: () => ({
    type: actions.MARK_AS_READ_BEGIN,
  }),

  markAsReadSuccess: (data) => ({
    type: actions.MARK_AS_READ_SUCCESS,
    data,
  }),

  markAsReadErr: (err) => ({
    type: actions.MARK_AS_READ_ERR,
    err,
  }),

  markAllAsReadBegin: () => ({
    type: actions.MARK_ALL_AS_READ_BEGIN,
  }),

  markAllAsReadSuccess: (data) => ({
    type: actions.MARK_ALL_AS_READ_SUCCESS,
    data,
  }),

  markAllAsReadErr: (err) => ({
    type: actions.MARK_ALL_AS_READ_ERR,
    err,
  }),

  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
};
export default actions;

import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';
import { RegisterProvider } from '../container/profile/authentication/RegisterProvider';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));
const ForgotPassword = lazy(() => import('../container/profile/authentication/ForgotPassword'));
const VerificationPage = lazy(() => import('../container/profile/authentication/VerificationPage'));
const ResetPassword = lazy(() => import('../container/profile/authentication/ResetPassword'));
const Lander = lazy(() => import('../container/profile/authentication/join/Lander'));

function LoginWithProvider() {
  return (
    <RegisterProvider>
      <Login />
    </RegisterProvider>
  );
}

function JoinWithProvider() {
  return (
    <RegisterProvider>
      <Lander />
    </RegisterProvider>
  );
}

const NotFound = () => <Redirect to="/" />;

function FrontendRoutes() {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path="/" component={LoginWithProvider} />
        <Route exact path="/verifying" component={VerificationPage} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/join-pamtree" component={JoinWithProvider} />

        <Route path="*" component={NotFound} />
      </Switch>
    </Suspense>
  );
}

export default AuthLayout(FrontendRoutes);

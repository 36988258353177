const actions = {
  CONTACT_CREATING_FROM_SELECT_BEGIN: 'CONTACT_CREATING_FROM_SELECT_BEGIN',
  CONTACT_CREATING_FROM_SELECT_SUCCESS: 'CONTACT_CREATING_FROM_SELECT_SUCCESS',
  CONTACT_CREATING_FROM_SELECT_ERR: 'CONTACT_CREATING_FROM_SELECT_ERR',
  CONTACT_CREATING_FROM_SELECT_CLOSE_MODEL: 'CONTACT_CREATING_FROM_SELECT_CLOSE_MODEL',
  CLEAR_SELECT_STATE: 'CLEAR_SELECT_STATE',

  CONTACT_FETCH_BEGIN: 'CONTACT_FETCH_BEGIN',
  CONTACT_FETCH_SUCCESS: 'CONTACT_FETCH_SUCCESS',
  CONTACT_FETCH_ERR: 'CONTACT_FETCH_ERR',

  CONTACT_CREATE_BEGIN: 'CONTACT_CREATE_BEGIN',
  CONTACT_CREATE_SUCCESS: 'CONTACT_CREATE_SUCCESS',
  CONTACT_CREATE_ERR: 'CONTACT_CREATE_ERR',

  CONTACT_DELETE_BEGIN: 'CONTACT_DELETE_BEGIN',
  CONTACT_DELETE_SUCCESS: 'CONTACT_DELETE_SUCCESS',
  CONTACT_DELETE_ERR: 'CONTACT_DELETE_ERR',

  CONTACT_BULK_DELETE_BEGIN: 'CONTACT_BULK_DELETE_BEGIN',
  CONTACT_BULK_DELETE_SUCCESS: 'CONTACT_BULK_DELETE_SUCCESS',
  CONTACT_BULK_DELETE_ERR: 'CONTACT_BULK_DELETE_ERR',

  CONTACT_BULK_ADD_TAG_BEGIN: 'CONTACT_BULK_ADD_TAG_BEGIN',
  CONTACT_BULK_ADD_TAG_SUCCESS: 'CONTACT_BULK_ADD_TAG_SUCCESS',
  CONTACT_BULK_ADD_TAG_ERR: 'CONTACT_BULK_ADD_TAG_ERR',

  CONTACT_BULK_STAGE_CHANGE_BEGIN: 'CONTACT_BULK_STAGE_CHANGE_BEGIN',
  CONTACT_BULK_STAGE_CHANGE_SUCCESS: 'CONTACT_BULK_STAGE_CHANGE_SUCCESS',
  CONTACT_BULK_STAGE_CHANGE_ERR: 'CONTACT_BULK_STAGE_CHANGE_ERR',

  CONTACT_BULK_ADD_TO_MAILCHIMP_BEGIN: 'CONTACT_BULK_ADD_TO_MAILCHIMP_BEGIN',
  CONTACT_BULK_ADD_TO_MAILCHIMP_SUCCESS: 'CONTACT_BULK_ADD_TO_MAILCHIMP_SUCCESS',
  CONTACT_BULK_ADD_TO_MAILCHIMP_ERR: 'CONTACT_BULK_ADD_TO_MAILCHIMP_ERR',

  CONTACT_UPDATE_BEGIN: 'CONTACT_UPDATE_BEGIN',
  CONTACT_UPDATE_SUCCESS: 'CONTACT_UPDATE_SUCCESS',
  CONTACT_UPDATE_ERR: 'CONTACT_UPDATE_ERR',

  CONTACT_VIEW_BEGIN: 'CONTACT_VIEW_BEGIN',
  CONTACT_VIEW_SUCCESS: 'CONTACT_VIEW_SUCCESS',
  CONTACT_VIEW_ERR: 'CONTACT_VIEW_ERR',

  STAGE_FETCH_BEGIN: 'STAGE_FETCH_BEGIN',
  STAGE_FETCH_SUCCESS: 'STAGE_FETCH_SUCCESS',
  STAGE_FETCH_ERR: 'STAGE_FETCH_ERR',

  STAGE_CREATE_BEGIN: 'STAGE_CREATE_BEGIN',
  STAGE_CREATE_SUCCESS: 'STAGE_CREATE_SUCCESS',
  STAGE_CREATE_ERR: 'STAGE_CREATE_ERR',

  STAGE_DELETE_BEGIN: 'STAGE_DELETE_BEGIN',
  STAGE_DELETE_SUCCESS: 'STAGE_DELETE_SUCCESS',
  STAGE_DELETE_ERR: 'STAGE_DELETE_ERR',

  STAGE_UPDATE_BEGIN: 'STAGE_UPDATE_BEGIN',
  STAGE_UPDATE_SUCCESS: 'STAGE_UPDATE_SUCCESS',
  STAGE_UPDATE_ERR: 'STAGE_UPDATE_ERR',

  CONTACT_ADD_TO_STAGE_BEGIN: 'CONTACT_ADD_TO_STAGE_BEGIN',
  CONTACT_ADD_TO_STAGE_SUCCESS: 'CONTACT_ADD_TO_STAGE_SUCCESS',
  CONTACT_ADD_TO_STAGE_ERR: 'CONTACT_ADD_TO_STAGE_ERR',

  CONTACT_RECENT_FETCH_BEGIN: 'CONTACT_RECENT_FETCH_BEGIN',
  CONTACT_RECENT_FETCH_SUCCESS: 'CONTACT_RECENT_FETCH_SUCCESS',
  CONTACT_RECENT_FETCH_ERR: 'CONTACT_RECENT_FETCH_ERR',

  GET_DUPLICATE_CONTACTS_BEGIN: 'GET_DUPLICATE_CONTACTS_BEGIN',
  GET_DUPLICATE_CONTACTS_SUCCESS: 'GET_DUPLICATE_CONTACTS_SUCCESS',
  GET_DUPLICATE_CONTACTS_ERR: 'GET_DUPLICATE_CONTACTS_ERR',

  // merge
  MERGE_CONTACTS_BEGIN: 'MERGE_CONTACTS_BEGIN',
  MERGE_CONTACTS_SUCCESS: 'MERGE_CONTACTS_SUCCESS',
  MERGE_CONTACTS_ERR: 'MERGE_CONTACTS_ERR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  contactCreatingFromSelectSuccess: (data) => ({
    type: actions.CONTACT_CREATING_FROM_SELECT_SUCCESS,
    data,
  }),

  clearSelectState: () => ({
    type: actions.CLEAR_SELECT_STATE,
  }),

  contactCreatingFromSelectErr: (err) => ({
    type: actions.CONTACT_CREATING_FROM_SELECT_ERR,
    err,
  }),
  contactFetchBegin: () => ({
    type: actions.CONTACT_FETCH_BEGIN,
  }),

  contactFetchSuccess: (data) => ({
    type: actions.CONTACT_FETCH_SUCCESS,
    data,
  }),

  contactFetchErr: (err) => ({
    type: actions.CONTACT_FETCH_ERR,
    err,
  }),

  contactCreateBegin: () => ({
    type: actions.CONTACT_CREATE_BEGIN,
  }),

  contactCreateSuccess: (data) => ({
    type: actions.CONTACT_CREATE_SUCCESS,
    data,
  }),

  contactCreateErr: (err) => ({
    type: actions.CONTACT_CREATE_ERR,
    err,
  }),

  contactDeleteBegin: () => ({
    type: actions.CONTACT_DELETE_BEGIN,
  }),

  contactDeleteSuccess: (data) => ({
    type: actions.CONTACT_DELETE_SUCCESS,
    data,
  }),

  contactDeleteErr: (err) => ({
    type: actions.CONTACT_DELETE_ERR,
    err,
  }),

  contactBulkDeleteBegin: () => ({
    type: actions.CONTACT_BULK_DELETE_BEGIN,
  }),

  contactBulkDeleteSuccess: (ids, data) => ({
    type: actions.CONTACT_BULK_DELETE_SUCCESS,
    ids,
    data,
  }),

  contactBulkDeleteErr: (ids, data) => ({
    type: actions.CONTACT_BULK_DELETE_ERR,
    ids,
    data,
  }),

  contactUpdateBegin: () => ({
    type: actions.CONTACT_UPDATE_BEGIN,
  }),

  contactUpdateSuccess: (data) => ({
    type: actions.CONTACT_UPDATE_SUCCESS,
    data,
  }),

  contactUpdateErr: (err) => ({
    type: actions.CONTACT_UPDATE_ERR,
    err,
  }),

  contactViewBegin: () => ({
    type: actions.CONTACT_VIEW_BEGIN,
  }),

  contactViewSuccess: (data) => ({
    type: actions.CONTACT_VIEW_SUCCESS,
    data,
  }),

  contactViewErr: (err) => ({
    type: actions.CONTACT_VIEW_ERR,
    err,
  }),

  stageFetchBegin: () => ({
    type: actions.STAGE_FETCH_BEGIN,
  }),

  stageFetchSuccess: (data) => ({
    type: actions.STAGE_FETCH_SUCCESS,
    data,
  }),

  stageFetchErr: (err) => ({
    type: actions.STAGE_FETCH_ERR,
    err,
  }),

  contactAddToStageBegin: () => ({
    type: actions.CONTACT_ADD_TO_STAGE_BEGIN,
  }),

  contactAddToStageSuccess: (data) => ({
    type: actions.CONTACT_ADD_TO_STAGE_SUCCESS,
    data,
  }),

  contactAddToStageErr: (err) => ({
    type: actions.CONTACT_ADD_TO_STAGE_ERR,
    err,
  }),

  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),

  contactBulkAddTagBegin: () => ({
    type: actions.CONTACT_BULK_ADD_TAG_BEGIN,
  }),

  contactBulkAddTagSuccess: (data) => ({
    type: actions.CONTACT_BULK_ADD_TAG_SUCCESS,
    data,
  }),

  contactBulkAddTagErr: (err) => ({
    type: actions.CONTACT_BULK_ADD_TAG_ERR,
    err,
  }),

  contactBulkStageChangeBegin: () => ({
    type: actions.CONTACT_BULK_STAGE_CHANGE_BEGIN,
  }),

  contactBulkStageChangeSuccess: (data) => ({
    type: actions.CONTACT_BULK_STAGE_CHANGE_SUCCESS,
    data,
  }),

  contactBulkStageChangeErr: (err) => ({
    type: actions.CONTACT_BULK_STAGE_CHANGE_ERR,
    err,
  }),

  contactBulkAddToMailchimpBegin: () => ({
    type: actions.CONTACT_BULK_ADD_TO_MAILCHIMP_BEGIN,
  }),

  contactBulkAddToMailchimpSuccess: (data) => ({
    type: actions.CONTACT_BULK_ADD_TO_MAILCHIMP_SUCCESS,
    data,
  }),

  contactBulkAddToMailchimpErr: (err) => ({
    type: actions.CONTACT_BULK_ADD_TO_MAILCHIMP_ERR,
    err,
  }),

  contactRecentFetchBegin: () => ({
    type: actions.CONTACT_RECENT_FETCH_BEGIN,
  }),

  contactRecentFetchSuccess: (data) => ({
    type: actions.CONTACT_RECENT_FETCH_SUCCESS,
    data,
  }),

  contactRecentFetchErr: (err) => ({
    type: actions.CONTACT_RECENT_FETCH_ERR,
    err,
  }),

  stageCreateBegin: () => ({
    type: actions.STAGE_CREATE_BEGIN,
  }),

  stageCreateSuccess: (data) => ({
    type: actions.STAGE_CREATE_SUCCESS,
    data,
  }),

  stageCreateErr: (err) => ({
    type: actions.STAGE_CREATE_ERR,
    err,
  }),

  stageDeleteBegin: () => ({
    type: actions.STAGE_DELETE_BEGIN,
  }),

  stageDeleteSuccess: (data) => ({
    type: actions.STAGE_DELETE_SUCCESS,
    data,
  }),

  stageDeleteErr: (err) => ({
    type: actions.STAGE_DELETE_ERR,
    err,
  }),

  stageUpdateBegin: () => ({
    type: actions.STAGE_UPDATE_BEGIN,
  }),

  stageUpdateSuccess: (data) => ({
    type: actions.STAGE_UPDATE_SUCCESS,
    data,
  }),

  stageUpdateErr: (err) => ({
    type: actions.STAGE_UPDATE_ERR,
    err,
  }),

  getDuplicateContactsBegin: () => ({
    type: actions.GET_DUPLICATE_CONTACTS_BEGIN,
  }),

  getDuplicateContactsSuccess: (data) => ({
    type: actions.GET_DUPLICATE_CONTACTS_SUCCESS,
    data,
  }),

  getDuplicateContactsErr: (err) => ({
    type: actions.GET_DUPLICATE_CONTACTS_ERR,
    err,
  }),

  mergeContactsBegin: () => ({
    type: actions.MERGE_CONTACTS_BEGIN,
  }),

  mergeContactsSuccess: (data) => ({
    type: actions.MERGE_CONTACTS_SUCCESS,
    data,
  }),

  mergeContactsErr: (err) => ({
    type: actions.MERGE_CONTACTS_ERR,
    err,
  }),
};

export default actions;

const actions = {
  PRODUCT_CREATING_FROM_SELECT_BEGIN: 'PRODUCT_CREATING_FROM_SELECT_BEGIN',
  PRODUCT_CREATING_FROM_SELECT_SUCCESS: 'PRODUCT_CREATING_FROM_SELECT_SUCCESS',
  PRODUCT_CREATING_FROM_SELECT_ERR: 'PRODUCT_CREATING_FROM_SELECT_ERR',
  PRODUCT_CREATING_FROM_SELECT_CLOSE_MODEL: 'PRODUCT_CREATING_FROM_SELECT_CLOSE_MODEL',
  CLEAR_SELECT_STATE: 'CLEAR_SELECT_STATE',

  PRODUCT_FETCH_BEGIN: 'PRODUCT_FETCH_BEGIN',
  PRODUCT_FETCH_SUCCESS: 'PRODUCT_FETCH_SUCCESS',
  PRODUCT_FETCH_ERR: 'PRODUCT_FETCH_ERR',

  PRODUCT_CREATE_BEGIN: 'PRODUCT_CREATE_BEGIN',
  PRODUCT_CREATE_SUCCESS: 'PRODUCT_CREATE_SUCCESS',
  PRODUCT_CREATE_ERR: 'PRODUCT_CREATE_ERR',

  PRODUCT_DELETE_BEGIN: 'PRODUCT_DELETE_BEGIN',
  PRODUCT_DELETE_SUCCESS: 'PRODUCT_DELETE_SUCCESS',
  PRODUCT_DELETE_ERR: 'PRODUCT_DELETE_ERR',

  PRODUCT_BULK_DELETE_BEGIN: 'PRODUCT_BULK_DELETE_BEGIN',
  PRODUCT_BULK_DELETE_SUCCESS: 'PRODUCT_BULK_DELETE_SUCCESS',
  PRODUCT_BULK_DELETE_ERR: 'PRODUCT_BULK_DELETE_ERR',

  PRODUCT_UPDATE_BEGIN: 'PRODUCT_UPDATE_BEGIN',
  PRODUCT_UPDATE_SUCCESS: 'PRODUCT_UPDATE_SUCCESS',
  PRODUCT_UPDATE_ERR: 'PRODUCT_UPDATE_ERR',

  PRODUCT_VIEW_BEGIN: 'PRODUCT_VIEW_BEGIN',
  PRODUCT_VIEW_SUCCESS: 'PRODUCT_VIEW_SUCCESS',
  PRODUCT_VIEW_ERR: 'PRODUCT_VIEW_ERR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  productCreatingFromSelectSuccess: (data) => ({
    type: actions.PRODUCT_CREATING_FROM_SELECT_SUCCESS,
    data,
  }),

  clearSelectState: () => ({
    type: actions.CLEAR_SELECT_STATE,
  }),

  productCreatingFromSelectErr: (err) => ({
    type: actions.PRODUCT_CREATING_FROM_SELECT_ERR,
    err,
  }),

  fetchProductsBegin: () => ({
    type: actions.PRODUCT_FETCH_BEGIN,
  }),

  fetchProductsSuccess: (data) => ({
    type: actions.PRODUCT_FETCH_SUCCESS,
    data,
  }),

  fetchProductsErr: (err) => ({
    type: actions.PRODUCT_FETCH_ERR,
    err,
  }),

  createProductBegin: () => ({
    type: actions.PRODUCT_CREATE_BEGIN,
  }),

  createProductSuccess: (data) => ({
    type: actions.PRODUCT_CREATE_SUCCESS,
    data,
  }),

  createProductErr: (err) => ({
    type: actions.PRODUCT_CREATE_ERR,
    err,
  }),

  deleteProductBegin: () => ({
    type: actions.PRODUCT_DELETE_BEGIN,
  }),

  deleteProductSuccess: (data) => ({
    type: actions.PRODUCT_DELETE_SUCCESS,
    data,
  }),

  deleteProductErr: (err) => ({
    type: actions.PRODUCT_DELETE_ERR,
    err,
  }),

  bulkDeleteProductBegin: () => ({
    type: actions.PRODUCT_BULK_DELETE_BEGIN,
  }),

  bulkDeleteProductSuccess: (ids, data) => ({
    type: actions.PRODUCT_BULK_DELETE_SUCCESS,
    ids,
    data,
  }),

  bulkDeleteProductErr: (err) => ({
    type: actions.PRODUCT_BULK_DELETE_ERR,
    err,
  }),

  updateProductBegin: () => ({
    type: actions.PRODUCT_UPDATE_BEGIN,
  }),

  updateProductSuccess: (data) => ({
    type: actions.PRODUCT_UPDATE_SUCCESS,
    data,
  }),

  updateProductErr: (err) => ({
    type: actions.PRODUCT_UPDATE_ERR,
    err,
  }),

  viewProductBegin: () => ({
    type: actions.PRODUCT_VIEW_BEGIN,
  }),

  viewProductSuccess: (data) => ({
    type: actions.PRODUCT_VIEW_SUCCESS,
    data,
  }),

  viewProductErr: (err) => ({
    type: actions.PRODUCT_VIEW_ERR,
    err,
  }),

  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
};

export default actions;

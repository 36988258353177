import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { DataService } from '../../../config/dataService';
import { setItem } from '../../../utility/localStorageControl';

export const RegisterContext = React.createContext();

export const RegisterProvider = (props) => {
  const [page, setPage] = useState(0);
  const [plans, setPlans] = useState([]);
  const [currency, setCurrency] = useState('gbp');
  const [planPrice, setPlanPrice] = useState(null);
  const [user, setUser] = useState(null);
  const [trialWarning, setTrialWarning] = useState(false);
  const [inviter, setInviter] = useState(null);
  const [inviteError, setInviteError] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);

  const register = async (values, loading = null) => {
    try {
      const res = await DataService.post('auth/signup', {
        first_name: values.firstName,
        surname: values.lastName,
        email: values.email,
        password: values.confirmPassword,
        company_id: values.company,
        company_name: values.selectedCompanyName,
        mobile_number: values.phoneNumber,
        terms: values.userAgreement,
        privacy: values.privacyPolicy,
        cookie: values.cookiePolicy,
        country_name: values.country ? values.country.name : '',
        country_iso: values.country ? values.country.iso2 : '',
        country_code: values.country ? values.country.dialCode : '',
        sponsor: '',
        plan_name: values.plan_name,
        plan_id: values.plan_id,
        timezone: moment.tz.guess(),
      });

      if (res.data.errors) {
        throw new Error(res.data.errors);
      }

      if (loading) {
        loading(false);
      }

      if (values.plan_name === 'Lite') {
        setItem('access_token', res.data.data.token);
        setItem('user', res.data.data.user);

        setTimeout(() => {
          window.location.reload();
        }, 2000);

        return res.data.data;
      }

      setUser(res.data.data);

      setTimeout(() => {
        setPage(page + 1);
      }, 500);

      return res.data.data;
    } catch (error) {
      if (loading) {
        loading(false);
      }
      return error.response.data;
    }
  };

  const joinTeam = async (values, loading = null) => {
    try {
      const res = await DataService.post('auth/invitation/join', {
        first_name: values.firstName,
        surname: values.lastName,
        email: values.email,
        password: values.confirmPassword,
        company_id: values.company_id,
        company_name: values.company_name,
        mobile_number: values.phoneNumber,
        terms: values.userAgreement,
        privacy: values.privacyPolicy,
        cookie: values.cookiePolicy,
        country_name: values.country ? values.country.name : '',
        country_iso: values.country ? values.country.iso2 : '',
        country_code: values.country ? values.country.dialCode : '',
        sponsor: '',
        plan_name: values.plan_name,
        plan_id: values.plan_id,
        with: values.with,
        timezone: moment.tz.guess(),
      });

      if (res.data.errors) {
        throw new Error(res.data.errors);
      }

      if (loading) {
        loading(false);
      }

      if (values.plan_name === 'Lite') {
        setItem('access_token', res.data.data.token);
        setItem('user', res.data.data.user);

        setTimeout(() => {
          window.location.reload();
        }, 2000);

        return res.data.data;
      }
      setUser(res.data.data);

      setTimeout(() => {
        setPage(page + 1);
      }, 500);

      return res.data.data;
    } catch (error) {
      if (loading) {
        loading(false);
      }
      return error.response.data;
    }
  };

  const validateInvite = async (id) => {
    setInviteLoading(true);
    try {
      const res = await DataService.get(`auth/invitation/validate/${id}`);

      if (res.data.status === 'error') {
        throw new Error(res.data.errors);
      }

      setInviter(res.data.data);
      setInviteLoading(false);

      return res.data.data;
    } catch (error) {
      setInviteError(true);
      setInviteLoading(false);
      return error.response.data;
    }
  };

  const subscribe = async (data) => {
    try {
      const res = await DataService.post('auth/create-subscription', data);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  const deleteAccount = async (id) => {
    try {
      const res = await DataService.delete(`my/${id}`);
      return res;
    } catch (error) {
      return error.response.data;
    }
  };

  const value = React.useMemo(
    () => ({
      plans,
      page: [page, setPage],
      currency: [currency, setCurrency],
      planPrice: [planPrice, setPlanPrice],
      trialWarning: [trialWarning, setTrialWarning],
      register,
      subscribe,
      deleteAccount,
      user,
      joinTeam,
      validateInvite,
      inviter,
      inviteError,
      inviteLoading,
    }),
    [
      plans,
      page,
      currency,
      planPrice,
      trialWarning,
      user,
      joinTeam,
      validateInvite,
      inviter,
      inviteError,
      inviteLoading,
    ],
  );

  useEffect(() => {
    const getPlans = async () => {
      const res = await DataService.get('subscription-plans');
      setPlans(res.data.data);
    };
    getPlans();
  }, []);

  return <RegisterContext.Provider value={value} {...props} />;
};

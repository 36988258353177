/* eslint-disable no-unused-vars */
import actions from './actions';
import history from '../../history';
import { DataService } from '../../config/dataService';
import { setItem, removeItem } from '../../utility/localStorageControl';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutErr, logoutSuccess } = actions;

const login = (value) => async (dispatch) => {
  try {
    dispatch(loginBegin());
    const data = await DataService.post('auth/login', value);

    if (data.data.errors) {
      throw new Error(data.data.errors);
    }

    setItem('access_token', data.data?.token);
    setItem('intercom_hash', data.data?.intercom_hash);
    setItem('user', data.data?.user);

    if (data?.data?.user?.email_verified_at) {
      setItem('verify', data.data.user.email_verified_at);
    }

    // "stripe_id": "sub_1NSufNLdRixVNJbf4um4uuzq",
    // "stripe_plan": "UK Professional Subscription",
    // "interval": "month",
    // "price": 19,
    // "stripeStatus": "active",
    // "current_period_start": "2023-07-12 05:28:41"

    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'g04dwk97',
      name: data.data.user.full_name,
      email: data.data.user.email,
      user_hash: data.data?.intercom_hash,
      number_of_contact: data.data.no_contacts,
      number_of_tasks: data.data.no_tasks,
      number_of_message: data.data.no_messages,
      stripe_id: data.data.stripe_id,
      stripe_plan: data.data.stripe_plan,
      stripe_plan_interval: data.data.interval,
      stripe_plan_price: data.data.price,
      stripe_subscription_status: data.data.stripeStatus,
      stripe_subscription_period_start_at: data.data.current_period_start,
    });

    dispatch(
      loginSuccess({
        login: true,
        verified: !!data.data.user.email_verified_at,
        user: data.data.user,
        intercom_hash: data.data?.intercom_hash,
      }),
    );

    if (data?.data?.user?.email_verified_at) {
      history.push('/admin');
    } else {
      history.push('/verify');
    }
  } catch (err) {
    dispatch(loginErr(err.response.data));

    setTimeout(() => {
      dispatch(loginErr(null));
    }, 1000);
  }
};

const logOut = () => async (dispatch) => {
  try {
    dispatch(logoutBegin());
    removeItem('access_token');
    removeItem('intercom_hash');
    removeItem('user');
    removeItem('verify');
    dispatch(logoutSuccess(null));

    window.Intercom('shutdown');

    history.push('/');
  } catch (err) {
    dispatch(logoutErr(err));
  }
};

export { login, logOut };

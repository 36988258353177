/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Row, Col } from 'antd';
import { FooterWrapper } from './style';
import FooItem from './FooItem';

import one from '../../../../static/img/foo-one.svg';
import two from '../../../../static/img/foo-two.svg';
import three from '../../../../static/img/foo-three.svg';

const Footer = () => (
  <FooterWrapper>
    <div className="foo">
      <Row gutter={[70, 16]}>
        <Col xl={8} xs={24}>
          <FooItem
            title="Manage your MLM business from the palm of your hand"
            description="Manage your contacts, create to-do lists, track customer and business activity, send and receive prospect messages, all at the tap of a button."
            icon={one}
          />
        </Col>
        <Col xl={8} xs={24}>
          <FooItem title="Easy & simple to use on mobiles" description="Easy & simple to use on mobiles" icon={two} />
        </Col>
        <Col xl={8} xs={24}>
          <FooItem
            title="Boost your business"
            description="Build a bigger team, generate more sales and create the future you're looking for faster than ever
                before with Pamtree!"
            icon={three}
          />
        </Col>
      </Row>
    </div>
  </FooterWrapper>
);

export default Footer;

const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  loginBegin: () => ({
    type: actions.LOGIN_BEGIN,
  }),

  loginSuccess: (data) => ({
    type: actions.LOGIN_SUCCESS,
    data,
  }),

  loginErr: (err) => ({
    type: actions.LOGIN_ERR,
    err,
  }),

  logoutBegin: () => ({
    type: actions.LOGOUT_BEGIN,
  }),

  logoutSuccess: (data) => ({
    type: actions.LOGOUT_SUCCESS,
    data,
  }),

  logoutErr: (err) => ({
    type: actions.LOGOUT_ERR,
    err,
  }),
};

export default actions;

import React from 'react';
import { Menu, Space } from 'antd';
import { NavLink, useRouteMatch, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';

function MenuItemComponent({ iconSrc, linkTo, label, key, toggleCollapsed, userPlan, upgradeIcon }) {
  const location = useLocation();
  const isCompletedTaskPage = location.pathname === '/admin/completed-task';
  const scheduledTaskPage = location.pathname === '/admin/scheduled';
  const todoTemplatePage = location.pathname === '/admin/todo-template';
  const messagePage = location.pathname === '/admin/messages-template';
  const funnelLink = location.pathname === '/admin/funnel-links';
  const funnelJoined = location.pathname === '/admin/funnel-joined';
  const funnelInvitation = location.pathname === '/admin/funnel-invitation';
  const onlineOrder = location.pathname === '/admin/online-order';
  const orderReport = location.pathname === '/admin/reports/order';
  const reportsOnlineOrder = location.pathname === '/admin/reports/onlineorder';
  const messageReport = location.pathname === '/admin/reports/message';
  const taskReport = location.pathname === '/admin/reports/task';
  const organisation = location.pathname === '/admin/organisation';

  const match =
    useRouteMatch({
      path: linkTo,
      exact: true,
    }) ||
    (linkTo === '/admin/todo-list' && isCompletedTaskPage) ||
    (linkTo === '/admin/todo-list' && scheduledTaskPage) ||
    (linkTo === '/admin/todo-list' && todoTemplatePage) ||
    (linkTo === '/admin/messages' && messagePage) ||
    (linkTo === '/admin/sales-funnel' && funnelLink) ||
    (linkTo === '/admin/sales-funnel' && funnelJoined) ||
    (linkTo === '/admin/sales-funnel' && funnelInvitation) ||
    (linkTo === '/admin/customer-tracker' && onlineOrder) ||
    (linkTo === '/admin/reports/contact' && orderReport) ||
    (linkTo === '/admin/reports/contact' && reportsOnlineOrder) ||
    (linkTo === '/admin/reports/contact' && messageReport) ||
    (linkTo === '/admin/reports/contact' && taskReport) ||
    (linkTo === '/admin/teams' && organisation);

  const item = (
    <Menu.Item
      onClick={toggleCollapsed}
      icon={
        <div className="anticon">
          <NavLink to={linkTo}>
            <ReactSVG src={iconSrc} />
          </NavLink>
        </div>
      }
      key={key}
    >
      <NavLink to={linkTo} className={match ? 'current' : ''}>
        <Space
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {label}
          {userPlan === 'free' && upgradeIcon && <ReactSVG className="t-1" src={upgradeIcon} />}
        </Space>
      </NavLink>
    </Menu.Item>
  );

  return item;
}

export default MenuItemComponent;

MenuItemComponent.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  toggleCollapsed: PropTypes.func.isRequired,
  userPlan: PropTypes.string.isRequired,
  upgradeIcon: PropTypes.string,
};

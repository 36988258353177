const actions = {
  // fetch
  NOTES_FETCH_BEGIN: 'NOTES_FETCH_BEGIN',
  NOTES_FETCH_SUCCESS: 'NOTES_FETCH_SUCCESS',
  NOTES_FETCH_ERR: 'NOTES_FETCH_ERR',

  // create
  NOTE_CREATE_BEGIN: 'NOTE_CREATE_BEGIN',
  NOTE_CREATE_SUCCESS: 'NOTE_CREATE_SUCCESS',
  NOTE_CREATE_ERR: 'NOTE_CREATE_ERR',

  // delete
  NOTE_DELETE_BEGIN: 'NOTE_DELETE_BEGIN',
  NOTE_DELETE_SUCCESS: 'NOTE_DELETE_SUCCESS',
  NOTE_DELETE_ERR: 'NOTE_DELETE_ERR',

  // update
  NOTE_UPDATE_BEGIN: 'NOTE_UPDATE_BEGIN',
  NOTE_UPDATE_SUCCESS: 'NOTE_UPDATE_SUCCESS',
  NOTE_UPDATE_ERR: 'NOTE_UPDATE_ERR',

  // show
  NOTE_SHOW_BEGIN: 'NOTE_SHOW_BEGIN',
  NOTE_SHOW_SUCCESS: 'NOTE_SHOW_SUCCESS',
  NOTE_SHOW_ERR: 'NOTE_SHOW_ERR',

  NOTE_CLEAR_ERROR: 'NOTE_CLEAR_ERROR',

  // create
  noteCreateBegin: () => ({
    type: actions.NOTE_CREATE_BEGIN,
  }),

  noteCreateSuccess: (data) => ({
    type: actions.NOTE_CREATE_SUCCESS,
    data,
  }),

  noteCreateErr: (err) => ({
    type: actions.NOTE_CREATE_ERR,
    err,
  }),

  // delete
  noteDeleteBegin: () => ({
    type: actions.NOTE_DELETE_BEGIN,
  }),

  noteDeleteSuccess: (data) => ({
    type: actions.NOTE_DELETE_SUCCESS,
    data,
  }),

  noteDeleteErr: (err) => ({
    type: actions.NOTE_DELETE_ERR,
    err,
  }),

  // update
  noteUpdateBegin: () => ({
    type: actions.NOTE_UPDATE_BEGIN,
  }),

  noteUpdateSuccess: (data) => ({
    type: actions.NOTE_UPDATE_SUCCESS,
    data,
  }),

  noteUpdateErr: (err) => ({
    type: actions.NOTE_UPDATE_ERR,
    err,
  }),

  // show
  noteShowBegin: () => ({
    type: actions.NOTE_SHOW_BEGIN,
  }),

  noteShowSuccess: (data) => ({
    type: actions.NOTE_SHOW_SUCCESS,
    data,
  }),

  noteShowErr: (err) => ({
    type: actions.NOTE_SHOW_ERR,
    err,
  }),

  // fetch
  noteFetchBegin: () => ({
    type: actions.NOTES_FETCH_BEGIN,
  }),

  noteFetchSuccess: (data) => ({
    type: actions.NOTES_FETCH_SUCCESS,
    data,
  }),

  noteFetchErr: (err) => ({
    type: actions.NOTES_FETCH_ERR,
    err,
  }),
};

export default actions;

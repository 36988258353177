/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const IntercomComponent = () => {
  const { login, intercom_hash, user } = useSelector((state) => state.auth);

  useEffect(() => {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'g04dwk97',
      name: login ? user.full_name : null,
      email: login ? user.email : null,
      user_hash: login ? intercom_hash : null,
    });
  }, []);

  return <div />;
};

export default IntercomComponent;

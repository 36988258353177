import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Col, Row, Spin } from 'antd';

import { login } from '../../redux/authentication/actionCreator';
import { Button } from '../buttons';

const Login = () => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('No email provided'),
    password: Yup.string().required('No password provided.'),
  });

  const { loading } = useSelector((state) => state.auth);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(login(values));
        setSubmitting(false);
      }}
    >
      {() => (
        <Form>
          <Row gutter={16}>
            <Col className="s-15" xs={24} md={10}>
              <Field className="form-control" name="email" type="email" placeholder="Email" />
              <ErrorMessage className="error-text" name="email" component="div" />
            </Col>
            <Col className="s-15" xs={24} md={10}>
              <Field
                className="form-control"
                name="password"
                type="password"
                placeholder="Password"
                autoComplete="on"
              />
              <ErrorMessage className="error-text" name="password" component="div" />
            </Col>
            <Col className="s-15" xs={24} md={4}>
              <Button type="primary" size="large" htmlType="submit">
                {loading && <Spin />} Login
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default Login;

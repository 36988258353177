import actions from './actions';

const {
  ORDER_FETCH_BEGIN,
  ORDER_FETCH_SUCCESS,
  ORDER_FETCH_ERR,

  ORDER_CREATE_BEGIN,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_ERR,

  ORDER_DELETE_BEGIN,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_ERR,

  ORDER_BULK_DELETE_BEGIN,
  ORDER_BULK_DELETE_SUCCESS,
  ORDER_BULK_DELETE_ERR,

  ORDER_UPDATE_BEGIN,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_ERR,

  ORDER_VIEW_BEGIN,
  ORDER_VIEW_SUCCESS,
  ORDER_VIEW_ERR,

  CLEAR_ERROR,
} = actions;

const initState = {
  loading: false,
  error: null,
  orders: [],
  order: {},
  count: 0,
  creditScore: 0,
};

// eslint-disable-next-line default-param-last
const OrderReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case ORDER_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ORDER_FETCH_SUCCESS:
      return {
        ...state,
        orders: data.data,
        count: data.total,
        loading: false,
        creditScore: data.credit_score,
      };
    case ORDER_FETCH_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ORDER_CREATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ORDER_CREATE_SUCCESS:
      return {
        ...state,
        order: data.data,
        loading: false,
      };
    case ORDER_CREATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ORDER_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ORDER_DELETE_SUCCESS:
      return {
        ...state,
        order: data.data,
        loading: false,
      };
    case ORDER_DELETE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ORDER_BULK_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ORDER_BULK_DELETE_SUCCESS:
      return {
        ...state,
        orders: data.data,
        loading: false,
      };
    case ORDER_BULK_DELETE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ORDER_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ORDER_UPDATE_SUCCESS:
      return {
        ...state,
        order: data.data,
        loading: false,
      };
    case ORDER_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case ORDER_VIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case ORDER_VIEW_SUCCESS:
      return {
        ...state,
        order: data,
        loading: false,
      };
    case ORDER_VIEW_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default OrderReducer;

const actions = {
  PROFILE_FETCH_BEGIN: 'PROFILE_FETCH_BEGIN',
  PROFILE_FETCH_SUCCESS: 'PROFILE_FETCH_SUCCESS',
  PROFILE_FETCH_ERR: 'PROFILE_FETCH_ERR',

  PROFILE_UPDATE_BEGIN: 'PROFILE_UPDATE_BEGIN',
  PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  PROFILE_UPDATE_ERR: 'PROFILE_UPDATE_ERR',

  PROFILE_DELETE_BEGIN: 'PROFILE_DELETE_BEGIN',
  PROFILE_DELETE_SUCCESS: 'PROFILE_DELETE_SUCCESS',
  PROFILE_DELETE_ERR: 'PROFILE_DELETE_ERR',

  CLEAR_ERROR: 'CLEAR_ERROR',

  profileFetchBegin: () => ({
    type: actions.PROFILE_FETCH_BEGIN,
  }),

  profileFetchSuccess: (data) => ({
    type: actions.PROFILE_FETCH_SUCCESS,
    data,
  }),

  profileFetchErr: (err) => ({
    type: actions.PROFILE_FETCH_ERR,
    err,
  }),

  profileUpdateBegin: () => ({
    type: actions.PROFILE_UPDATE_BEGIN,
  }),

  profileUpdateSuccess: (data) => ({
    type: actions.PROFILE_UPDATE_SUCCESS,
    data,
  }),

  profileUpdateErr: (err) => ({
    type: actions.PROFILE_UPDATE_ERR,
    err,
  }),

  profileDeleteBegin: () => ({
    type: actions.PROFILE_DELETE_BEGIN,
  }),

  profileDeleteSuccess: (data) => ({
    type: actions.PROFILE_DELETE_SUCCESS,
    data,
  }),

  profileDeleteErr: (err) => ({
    type: actions.PROFILE_DELETE_ERR,
    err,
  }),

  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),
};

export default actions;

import actions from './actions';

const {
  PROFILE_FETCH_BEGIN,
  PROFILE_FETCH_SUCCESS,
  PROFILE_FETCH_ERR,

  PROFILE_UPDATE_BEGIN,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_ERR,

  PROFILE_DELETE_BEGIN,
  PROFILE_DELETE_SUCCESS,
  PROFILE_DELETE_ERR,

  CLEAR_ERROR,
} = actions;

const initialState = {
  loading: false,
  error: null,
  profile: null,
  plan: null,
  stripe: null,
};

// eslint-disable-next-line default-param-last
const ProfileReducer = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case PROFILE_FETCH_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: data,
        plan: data.subscriptions?.stripe_price,
        stripe: data.stripe,
      };
    case PROFILE_FETCH_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case PROFILE_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: data,
      };
    case PROFILE_UPDATE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case PROFILE_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: null,
      };
    case PROFILE_DELETE_ERR:
      return {
        ...state,
        loading: false,
        error: err,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default ProfileReducer;

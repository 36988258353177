const actions = {
  // message template fetch
  MESSAGE_TEMPLATE_FETCH_BEGIN: 'MESSAGE_TEMPLATE_FETCH_BEGIN',
  MESSAGE_TEMPLATE_FETCH_SUCCESS: 'MESSAGE_TEMPLATE_FETCH_SUCCESS',
  MESSAGE_TEMPLATE_FETCH_ERR: 'MESSAGE_TEMPLATE_FETCH_ERR',

  // message template create
  MESSAGE_TEMPLATE_CREATE_BEGIN: 'MESSAGE_TEMPLATE_CREATE_BEGIN',
  MESSAGE_TEMPLATE_CREATE_SUCCESS: 'MESSAGE_TEMPLATE_CREATE_SUCCESS',
  MESSAGE_TEMPLATE_CREATE_ERR: 'MESSAGE_TEMPLATE_CREATE_ERR',

  // message template update
  MESSAGE_TEMPLATE_UPDATE_BEGIN: 'MESSAGE_TEMPLATE_UPDATE_BEGIN',
  MESSAGE_TEMPLATE_UPDATE_SUCCESS: 'MESSAGE_TEMPLATE_UPDATE_SUCCESS',
  MESSAGE_TEMPLATE_UPDATE_ERR: 'MESSAGE_TEMPLATE_UPDATE_ERR',

  // message template delete
  MESSAGE_TEMPLATE_DELETE_BEGIN: 'MESSAGE_TEMPLATE_DELETE_BEGIN',
  MESSAGE_TEMPLATE_DELETE_SUCCESS: 'MESSAGE_TEMPLATE_DELETE_SUCCESS',
  MESSAGE_TEMPLATE_DELETE_ERR: 'MESSAGE_TEMPLATE_DELETE_ERR',

  // message template bulk delete
  MESSAGE_TEMPLATE_BULK_DELETE_BEGIN: 'MESSAGE_TEMPLATE_BULK_DELETE_BEGIN',
  MESSAGE_TEMPLATE_BULK_DELETE_SUCCESS: 'MESSAGE_TEMPLATE_BULK_DELETE_SUCCESS',
  MESSAGE_TEMPLATE_BULK_DELETE_ERR: 'MESSAGE_TEMPLATE_BULK_DELETE_ERR',

  // message template view
  MESSAGE_TEMPLATE_VIEW_BEGIN: 'MESSAGE_TEMPLATE_VIEW_BEGIN',
  MESSAGE_TEMPLATE_VIEW_SUCCESS: 'MESSAGE_TEMPLATE_VIEW_SUCCESS',
  MESSAGE_TEMPLATE_VIEW_ERR: 'MESSAGE_TEMPLATE_VIEW_ERR',

  // message template clear error
  CLEAR_ERROR: 'CLEAR_ERROR',
  CLEAR_ERROR_MESSAGE: 'CLEAR_ERROR_MESSAGE',

  // fetch
  fetchMessageTemplates: () => ({
    type: actions.MESSAGE_TEMPLATE_FETCH_BEGIN,
  }),

  fetchMessageTemplatesSuccess: (data) => ({
    type: actions.MESSAGE_TEMPLATE_FETCH_SUCCESS,
    data,
  }),

  fetchMessageTemplatesErr: (err) => ({
    type: actions.MESSAGE_TEMPLATE_FETCH_ERR,
    data: err,
  }),

  // message template create
  messageTemplateCreatingBegin: () => ({
    type: actions.MESSAGE_TEMPLATE_CREATE_BEGIN,
  }),

  messageTemplateCreatingSuccess: (data) => ({
    type: actions.MESSAGE_TEMPLATE_CREATE_SUCCESS,
    data,
  }),

  messageTemplateCreatingErr: (err) => ({
    type: actions.MESSAGE_TEMPLATE_CREATE_ERR,
    data: err,
  }),

  // message template update
  messageTemplateUpdatingBegin: () => ({
    type: actions.MESSAGE_TEMPLATE_UPDATE_BEGIN,
  }),

  messageTemplateUpdatingSuccess: (data) => ({
    type: actions.MESSAGE_TEMPLATE_UPDATE_SUCCESS,
    data,
  }),

  messageTemplateUpdatingErr: (err) => ({
    type: actions.MESSAGE_TEMPLATE_UPDATE_ERR,
    data: err,
  }),

  // message template delete
  messageTemplateDeletingBegin: () => ({
    type: actions.MESSAGE_TEMPLATE_DELETE_BEGIN,
  }),

  messageTemplateDeletingSuccess: (data) => ({
    type: actions.MESSAGE_TEMPLATE_DELETE_SUCCESS,
    data,
  }),

  messageTemplateDeletingErr: (err) => ({
    type: actions.MESSAGE_TEMPLATE_DELETE_ERR,
    data: err,
  }),

  // message template bulk delete
  messageTemplateBulkDeletingBegin: () => ({
    type: actions.MESSAGE_TEMPLATE_BULK_DELETE_BEGIN,
  }),

  messageTemplateBulkDeletingSuccess: (data) => ({
    type: actions.MESSAGE_TEMPLATE_BULK_DELETE_SUCCESS,
    data,
  }),

  messageTemplateBulkDeletingErr: (err) => ({
    type: actions.MESSAGE_TEMPLATE_BULK_DELETE_ERR,
    data: err,
  }),

  // message template view
  messageTemplateViewingBegin: () => ({
    type: actions.MESSAGE_TEMPLATE_VIEW_BEGIN,
  }),

  messageTemplateViewingSuccess: (data) => ({
    type: actions.MESSAGE_TEMPLATE_VIEW_SUCCESS,
    data,
  }),

  messageTemplateViewingErr: (err) => ({
    type: actions.MESSAGE_TEMPLATE_VIEW_ERR,
    data: err,
  }),

  // message template clear error
  clearError: () => ({
    type: actions.CLEAR_ERROR,
  }),

  clearViewMessage: () => ({
    type: actions.CLEAR_ERROR_MESSAGE,
  }),
};
export default actions;

/* eslint-disable no-unused-vars */
import React from 'react';
import { Row, Col, message } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TopNavWrapper } from './style';
import LoginForm from '../../../../components/forms/Login';
import logo from '../../../../static/img/logo.svg';
import useDeviceWidth from '../../../../hooks/responsive';

const TopNav = () => {
  const location = useLocation();
  const deviceWidth = useDeviceWidth();

  const { error } = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (error) {
      message.error(error.message, 3);
    }
  }, [error]);

  return (
    <TopNavWrapper>
      <Row>
        <Col className="s-15" flex="auto">
          <img src={logo} alt="Pamtree Logo" height="35" />
        </Col>
        {deviceWidth > 767 && (
          <Col className="s-15" md={8} flex="none">
            {location.pathname === '/' && (
              <>
                <LoginForm />
                <Link className="underline small-link" to="/forgotpassword">
                  Forgot password ?
                </Link>
              </>
            )}
          </Col>
        )}
      </Row>
    </TopNavWrapper>
  );
};

export default TopNav;
